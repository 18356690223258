<template>
  <div class="popup-container align-items-center" v-show="blnCloseForm == true">
    <transition name="zoom">
      <AlertPopup
        v-show="blnCloseForm == true"
        :altMsgBox="$t('216')"
        class="warning"
        classOkBtn="btn-warning text-white"
        classCancelBtn="text-warning"
        artIcon="WarningOutline"
        :strButtonSubmit="$t('214')"
        :strButtonCancel="$t('215')"
        @confirm="confirmCloseForm($event)"
        classIcon="text-secondary fill-secondary"
      />
    </transition>
    <transition name="fade">
      <div
        class="overlay"
        v-show="blnCloseForm == true"
        @click="
          () => {
            blnCloseForm = false;
          }
        "
      ></div>
    </transition>
  </div>
  <div
    class="popup-container align-items-center"
    v-show="blnChangeDatatype == true"
  >
    <transition name="zoom">
      <AlertPopup
        v-show="blnChangeDatatype == true"
        :altMsgBox="$t('441')"
        class="warning"
        classOkBtn="btn-warning text-white"
        classCancelBtn="text-warning"
        artIcon="WarningOutline"
        :strButtonSubmit="$t('214')"
        :strButtonCancel="$t('215')"
        @confirm="confirmselectDataType($event)"
        classIcon="text-secondary fill-secondary"
      />
    </transition>
    <transition name="fade">
      <div
        class="overlay"
        v-show="blnChangeDatatype == true"
        @click="confirmselectDataType(false)"
      ></div>
    </transition>
  </div>

  <div
    class="popup-container align-items-center"
    v-show="blnChangeFieldtype == true"
  >
    <transition name="zoom">
      <AlertPopup
        v-show="blnChangeFieldtype == true"
        :altMsgBox="$t('888')"
        class="warning"
        classOkBtn="btn-warning text-white"
        classCancelBtn="text-warning"
        artIcon="WarningOutline"
        :strButtonSubmit="$t('214')"
        :strButtonCancel="$t('215')"
        @confirm="confirmselectFieldType($event)"
        classIcon="text-secondary fill-secondary"
      />
    </transition>
    <transition name="fade">
      <div
        class="overlay"
        v-show="blnChangeFieldtype == true"
        @click="confirmselectFieldType(false)"
      ></div>
    </transition>
  </div>
  <!-- <div class="popup-container align-items-center" v-show="blnAdddata == true">
    <transition name="zoom">
      <AlertPopup
        v-show="blnAdddata == true"
        :altMsgBox="$t('905')"
        class="warning"
        classOkBtn="btn-warning text-white"
        classCancelBtn="text-warning"
        artIcon="WarningOutline"
        :strButtonSubmit="$t('214')"
        :strButtonCancel="$t('215')"
        @confirm="confirmaddData($event)"
        classIcon="text-secondary fill-secondary"
      />
    </transition>
    <transition name="fade">
      <div
        class="overlay"
        v-show="blnAdddata == true"
        @click="confirmaddData(false)"
      ></div>
    </transition>
  </div> -->
  <div
    class="rounded-2 shadow-sm bg-white me-lg-5 me-sm-0 layout pt-6 pb-5 px-5 position-relative"
    :class="device == 'mobile' ? 'mb-5 me-0 w-100' : 'form-userfield mb-4'"
  >
    <div class="mb-5" id="FieldName">
      <div class="d-flex justify-content-between align-items-center mb-4">
        <Label :label="$t('4')" class="fnt-medium fnt-subtitle" />
        <Label
          :label="$t('34')"
          class="text-danger-500 fnt-regular fnt-caption"
        />
      </div>
      <InputText
        :value="UserFielddata.strFieldName"
        :formdata="UserFielddata.strFieldName"
        :validate="'letterEN'"
        :label="$t('126')"
        ref="strFieldName"
        @input="updateFieldname('strFieldName', $event)"
        :alertMsg="$t('202') + $t('126')"
        :request="true"
        style="z-index: -1"
      />
    </div>
    <div class="d-flex justify-content-between align-items-center mb-4">
      <Label :label="$t('875')" class="fnt-medium fnt-subtitle" />
      <!-- <Label
        :label="$t('34')"
        class="text-danger-500 fnt-regular fnt-caption"
      /> -->
    </div>

    <div class="row gx-3">
      <div class="col-lg-6 col-sm-12 mb-4" id="TextDisplatTH">
        <InputText
          :value="UserFielddata.strLabelTH"
          :formdata="UserFielddata.strLabelTH"
          :validate="'TextDisplayTH'"
          :label="$t('876')"
          ref="strLabelTH"
          @input="updateFieldname('strLabelTH', $event)"
          :alertMsg="$t('202') + $t('192')"
          :request="true"
        />
      </div>
      <div class="col-lg-6 col-sm-12 mb-4" id="TextDisplatEN">
        <InputText
          :value="UserFielddata.strLabelEN"
          :formdata="UserFielddata.strLabelEN"
          :validate="'TextDisplayEN'"
          :label="$t('877')"
          ref="strLabelEN"
          @input="updateFieldname('strLabelEN', $event)"
          :alertMsg="$t('202') + $t('126')"
          :request="true"
          style="z-index: -1"
        />
      </div>
    </div>
    <Label :label="$t('830')" class="mb-3 fnt-medium fnt-subtitle" />
    <div class="mb-3" :class="device == 'mobile' ? 'flex-column' : ''">
      <template v-for="(item, index) in optionListRender" :key="index">
        <Checkbox
          @select="selectOption(index, $event)"
          :ref="'option' + index"
          :checkboxLabel="$t(item.name)"
          :checked="item.checked"
          :canChange="detectCheckbok(index)"
          class="me-4"
        />
      </template>
    </div>

    <Label
      :label="$t('832')"
      labelclassed="fnt-medium fnt-subtitle"
      class="mb-4"
    />
    <SelectDropdown
      :listDataSelection="ListSearch"
      :strName="$t('127')"
      :strSelectedValue="listActiveFilters"
      :relations="''"
      class="mt-3 mb-4 d-none"
      :listDataSelected="listDataSelected"
      :remove="false"
      @selected="FieldType($event)"
      :canChange="
        action == 'Update' ? !inactive : listSearch.length == 1 ? false : true
      "
      :value="formdata.intFieldType == undefined ? 2 : formdata.intFieldType"
      ref="SelectDropdown"
    />
    <!-- Radio  fieldtype -->
    <div class="d-flex mb-3">
      <template v-for="(item, index) in listRadioFieldtype" :key="index">
        <Radio
          inputname="radioFieldtype"
          :name="$t(item.name)"
          class="me-4 fnt-body"
          :usechecked="
            formdata.intFieldType == undefined
              ? index == 0
                ? true
                : false
              : formdata.intFieldType == item.id
              ? true
              : false
          "
          :canChange="
            iscandelete == true
              ? true
              : UserFielddata.intTemplateDifinest == 1
              ? index == 1
                ? true
                : false
              : false
          "
          @change="FieldType(item.id)"
          :ref="'radioFieldtype' + item.id"
        />
      </template>
    </div>

    <!-- {{ processdata }} -->
    <div class="w-100 bg-black-100 rounded p-3 ps-4" v-if="processdata == true">
      <!-- :canChange="iscandelete" -->
      <div v-if="fieldtype == 3">
        <Label
          :label="$t('129')"
          labelclassed="fnt-medium fnt-subtitle"
          class="mb-4"
        />
        <div
          class="d-flex mb-4"
          :class="device == 'mobile' ? 'flex-column' : ''"
        >
          <!-- <template v-for="(item, index) in radioList" :key="index">
            <Radio
              @change="selectDataType(item.id)"
              inputname="datatype1"
              :name="$t(item.name)"
              class="me-4 fnt-body"
              :ref="'radio3' + item.id"
              :canChange="action == 'Update' ? inactive : false"
              :usechecked="
                typeof formdata.strDatatype != 'undefined'
                  ? action == 'Update'
                    ? item.id == formdata.strDatatype
                      ? true
                      : false
                    : item.id == strDatatype
                    ? true
                    : false
                  : item.id == strDatatype
                  ? true
                  : false
              "
            />
          </template> -->
          <DropdownList
            :btnRemove="false"
            :List="true"
            :strName="$t('129')"
            star="*"
            :DropdownItem="listDropdrown"
            :selectedValue="
              typeof UserFielddata.strDatatype != 'undefined'
                ? action == 'Update'
                  ? UserFielddata.strDatatype
                  : 1
                : 1
            "
            :showTxtInp="iscandelete == true ? true : false"
            @input="selectDataType($event)"
            ref="dropdownDatatype3"
          />
        </div>
        <div v-if="strDatatype == 1">
          <Label
            :label="$t('835')"
            labelclassed="fnt-medium fnt-subtitle"
            class="mb-4"
          />
          <div class="d-flex">
            <template v-for="(item, index) in listRadioLang" :key="index">
              <Radio
                inputname="radioDatatype"
                :name="$t(item.name)"
                class="me-4 fnt-body"
                :ref="'radiolang'"
                :usechecked="
                  UserFielddata.intLangcontrolst == undefined
                    ? index == 0
                      ? true
                      : false
                    : UserFielddata.intLangcontrolst == item.id
                    ? true
                    : false
                "
                :canChange="iscandelete == true ? true : false"
                @change="LangControl(item.id)"
              />
            </template>
          </div>
        </div>
      </div>

      <div v-if="fieldtype == 2">
        <!-- <p>Fixed</p> -->
        <Label
          :label="$t('129')"
          labelclassed="fnt-medium fnt-subtitle"
          class="mb-4"
        />

        <div
          class="d-flex mb-3"
          :class="device == 'mobile' ? 'flex-column' : ''"
        >
          <DropdownList
            :List="true"
            :btnRemove="false"
            :strName="$t('129')"
            star="*"
            :DropdownItem="listDropdrown"
            :selectedValue="
              typeof UserFielddata.strDatatype != 'undefined'
                ? action == 'Update'
                  ? blnChangeDatatype == true ||
                    stroldDatatype != UserFielddata.strDatatype
                    ? UserFielddata.strDatatype
                    : UserFielddata.strDatatype
                  : UserFielddata.strDatatype
                : UserFielddata.strDatatype
            "
            :showTxtInp="iscandelete == true ? true : false"
            @input="selectDataType($event)"
            ref="dropdownDatatype2"
          />
          <!-- <template v-for="(item, index) in radioList" :key="index">
            <Radio
              @change="selectDataType(item.id)"
              inputname="datatype2"
              :name="$t(item.name)"
              class="me-4 fnt-body"
              :ref="'radio3' + item.id"
              :canChange="action == 'Update' ? inactive : false"
              :usechecked="
                typeof formdata.strDatatype != 'undefined'
                  ? item.id === formdata.strDatatype
                    ? formdata.strDatatype === strDatatype
                      ? true
                      : item.id == strDatatype
                      ? true
                      : false
                    : item.id == strDatatype
                    ? true
                    : false
                  : item.id == strDatatype
                  ? true
                  : false
              "
            />
          </template> -->
        </div>

        <div v-if="strDatatype == 1" class="d-flex flex-column w-100 gap-3">
          <Longtext
            :value="UserFielddata.strValue"
            :formdata="UserFielddata.strValue"
            :validate="''"
            @input="updateData('strValue', $event, 3)"
            :label="$t('120') + ' *'"
            :showTxtInp="iscandelete == undefined ? false : iscandelete"
            alertMsg="419"
            maxlength="500"
            ref="strDatatype1"
          />
        </div>
        <div v-if="strDatatype == 2" class="d-flex flex-column w-100">
          <InputText
            :value="UserFielddata.strValue"
            :formdata="UserFielddata.strValue"
            @input="updateData('strValue', $event, 3)"
            :validate="'numeric'"
            :label="$t('121')"
            :showTxtInp="iscandelete == undefined ? false : iscandelete"
            class="w-100"
            alertMsg="419"
            ref="strDatatype2"
          />
        </div>
        <div v-if="strDatatype == 3" class="d-flex flex-column w-100">
          <InputText
            :value="UserFielddata.strValue"
            :formdata="UserFielddata.strValue"
            @input="updateData('strValue', $event, 3)"
            :validate="'currency'"
            :label="$t('123')"
            class="w-100"
            :labelSub="': 1,000 *'"
            :showTxtInp="iscandelete == undefined ? false : iscandelete"
            alertMsg="419"
            ref="strDatatype3"
          />
        </div>
        <div v-if="strDatatype == 5" class="d-flex flex-column w-100">
          <inputTimeKey
            :label="$t('140') + ' *'"
            style="height: 90%"
            @strValue="
              (event) => {
                this.UserFielddata['strValue'] = event;
              }
            "
            :ValueHours="
              UserFielddata.strValue == undefined
                ? '00'
                : UserFielddata.strValue.split(':')[0]
            "
            :ValueMinutesl="
              UserFielddata.strValue == undefined
                ? '00'
                : UserFielddata.strValue.split(':')[1]
            "
            class="w-100"
            alertMsg="419"
            :inactive="iscandelete == undefined ? false : iscandelete"
            ref="strDatatype4"
          />
        </div>
        <div v-if="strDatatype == 4" class="d-flex flex-column w-100">
          <inputCalendaSelecDateLength
            class=""
            :showFirstTime="false"
            :showSeconTime="false"
            :label="$t('139') + ' *'"
            :labelFirstTime="$t('140')"
            :valueCalenda="UserFielddata.strValue"
            @input="
              (event) => {
                if (event != '' && event != undefined)
                  this.UserFielddata['strValue'] = event;
              }
            "
            ref="strDatatype5"
          />
        </div>
        <div v-if="strDatatype == 6" class="d-flex flex-column w-100">
          <inputCalendaSelecDateLength
            class=""
            :showFirstTime="true"
            :showSeconTime="false"
            :label="$t('146') + ' *'"
            :labelFirstTime="$t('140')"
            :valueCalenda="UserFielddata.strValue"
            @input="
              (event) => {
                if (event != '' && event != undefined)
                  this.UserFielddata['strValue'] = event;
              }
            "
            ref="strDatatype6"
          />
          <div v-if="strDatatype == 7" class="d-flex flex-column w-100">
            <InputText
              :value="UserFielddata.strValue"
              :formdata="UserFielddata.strValue"
              @input="updateData('strValue', $event, 3)"
              :validate="'email'"
              :label="$t('70')"
              class="w-100"
              :showTxtInp="iscandelete == undefined ? false : iscandelete"
              alertMsg="419"
              ref="strDatatype7"
            />
          </div>
          <div v-if="strDatatype == 8" class="d-flex flex-column w-100">
            <InputText
              :value="UserFielddata.strValue"
              :formdata="UserFielddata.strValue"
              @input="updateData('strValue', $event, 3)"
              :validate="'letterEN'"
              :label="$t('70')"
              class="w-100"
              :showTxtInp="iscandelete == undefined ? false : iscandelete"
            />
          </div>
        </div>
        <div v-if="strDatatype == 7" class="d-flex flex-column w-100">
          <InputText
            :value="UserFielddata.strValue"
            :formdata="UserFielddata.strValue"
            @input="updateData('strValue', $event, 3)"
            :label="$t('70') + ' *'"
            labelSub=": name@email.com"
            validate="email"
            :showTxtInp="iscandelete == undefined ? false : iscandelete"
            alertMsg="419"
            ref="strDatatype7"
          />
        </div>
        <div v-if="strDatatype == 8" class="d-flex flex-column w-100 mt-4">
          <InputFile
            :subname="$t('405')"
            :name="$t('203')"
            datavalue="strImageValue"
            maxSize="2000000"
            widthicon="32"
            heighticon="32"
            :value="
              srcImgFixed == '' ? UserFielddata.strImageValue : srcImgFixed
            "
            @input="updateFieldname('strImageValue', $event)"
            :filetype="'image/jpeg,image/png'"
            alertMsg="419"
            ref="strDatatype8"
          />
          <span class="fnt-medium">{{ $t("374") }}</span>
          <!-- Label upload icon -->
        </div>
        <div v-if="strDatatype == 9" class="d-flex flex-column w-100">
          <InputText
            :value="UserFielddata.strValue"
            :formdata="UserFielddata.strValue"
            @input="updateData('strValue', $event, 3)"
            :label="$t('69') + ' *'"
            labelSub=""
            validate="phonenumber"
            :showTxtInp="iscandelete == undefined ? false : iscandelete"
            alertMsg="419"
            ref="strDatatype9"
          />
        </div>
        <!-- กรุณากรอกข้อมูล -->
        <!-- <div
        :class="alertMsgStatus == true ? '' : 'd-none'"
          class="
            d-flex
            alertInput
            align-items-center
            p-0
            mt-1
            fnt-body fnt-regular
          "
        >
          <Icon
            icon="carbon:warning"
            class="me-1"
          />{{ $t(alertMsgInput) }}
        </div> -->
      </div>
      <div v-if="fieldtype == 4">
        <Label
          :label="$t('129')"
          labelclassed="fnt-medium fnt-subtitle"
          class="mb-3"
        />
        <div class="mb-4" v-if="blnShowLookUpList == false && strDatatype != 8">
          <Button
            @input="
              () => {
                blnShowLookUpList = !blnShowLookUpList;
                this.$refs.dropdownDatatype4.inactive = false;
                this.$refs.dropdownComponenttype4.inactive = false;
              }
            "
            icon="EditFill"
            classBtnIcon="fill-secondary"
            :name="$t('426', { fieldname: $t('834').toLocaleLowerCase() })"
            :widthIcon="24"
            :heightIcon="24"
            className="text-secondary"
            class="d-flex align-items-center p-0 mb-2"
            :class="iscandelete == true ? 'disabled' : ''"
          />
        </div>
        <div class="mb-4" v-if="blnShowLookUpList == false && strDatatype == 8">
          <Button
            v-if="strDatatype == 8 && blnclick == true"
            @click="editimagebutton()"
            :name="$t('426', { fieldname: $t('834').toLocaleLowerCase() })"
            icon="EditFill"
            classBtnIcon="fill-secondary"
            className="fill-secondary text-secondary"
            class="d-flex align-items-center p-0 mb-2"
            :class="iscandelete == true ? 'disabled' : ''"
          />
        </div>

        <div
          class="d-flex mb-3 row"
          :class="device == 'mobile' ? 'flex-column' : ''"
        >
          <div class="" :class="strDatatype == 8 ? 'col' : 'col-6 pe-0'">
            <DropdownList
              :List="true"
              :btnRemove="false"
              :strName="$t('129')"
              star="*"
              :DropdownItem="listDropdrown"
              :selectedValue="
                typeof formdata.strDatatype != 'undefined'
                  ? action == 'Update'
                    ? blnChangeDatatype == true ||
                      stroldDatatype != formdata.strDatatype
                      ? UserFielddata.strDatatype
                      : formdata.strDatatype
                    : 1
                  : UserFielddata.strDatatype
              "
              :showTxtInp="blnShowLookUpList == false ? true : false"
              @input="selectDataType($event)"
              ref="dropdownDatatype4"
            />
          </div>
          <div class="col-6" v-if="strDatatype != 8">
            <DropdownList
              :List="true"
              :btnRemove="false"
              :strName="$t('1015')"
              star="*"
              :DropdownItem="listDropdrownComponent"
              :selectedValue="
                typeof formdata.intDisplayType == 'undefined'
                  ? UserFielddata.intDisplayType
                  : formdata.intDisplayType == 0
                  ? UserFielddata.intDisplayType
                  : formdata.intDisplayType
              "
              :showTxtInp="blnShowLookUpList == false ? true : false"
              @input="selectComponentType($event)"
              ref="dropdownComponenttype4"
            />
          </div>
        </div>
        <div
          class="row rounded-2 p-3"
          :class="
            alertMsgStatus == true
              ? 'border-danger'
              : '' || blnShowLookUpList == false
              ? 'ps-0 py-0'
              : ''
          "
        >
          <div>
            <Label
              :label="$t('189')"
              class="mb-3 mt-3 fnt-medium fnt-subtitle"
            />

            <div class="d-flex w-100 mb-4" v-if="strDatatype == 8">
              <div class="container ps-0" v-if="strDatatype == 8">
                <div class="row mb-3" v-if="blnclick == false">
                  <div
                    v-for="(item, index) in listLookUpImage"
                    :key="index"
                    class="col-2"
                    :class="
                      blnclick == false
                        ? index == 0 ||
                          index == 1 ||
                          index <= listLengthImage.length
                          ? ''
                          : 'disabled'
                        : item == undefined || item.scr == ''
                        ? 'd-none'
                        : ''
                    "
                    :ref="'file'"
                    :index="index"
                  >
                    <File
                      :blnclick="blnclick"
                      @input="filedata($event, index)"
                      subname="JPEG,PNG"
                      :value="
                        item.src == undefined
                          ? item.filepath == undefined
                            ? item.FormFiles
                            : item.filepath
                          : item.src
                      "
                      @remove="removedata($event, index, item.fileID)"
                      :maxSize="2000000"
                      :index="index"
                      :ref="'fileLookupImage' + index"
                    />
                  </div>
                </div>
                <div v-if="blnclick == true" class="row">
                  <div
                    v-for="(item, index) in listSrcImage"
                    :key="index"
                    class="col-2"
                    :class="
                      blnclick == false
                        ? index == 0 ||
                          index == 1 ||
                          index <= listLengthImage.length
                          ? ''
                          : 'disabled'
                        : item == ''
                        ? 'd-none'
                        : ''
                    "
                  >
                    <File
                      :blnclick="blnclick"
                      @input="filedata($event, index)"
                      subname="JPEG,PNG"
                      @remove="removedata($event, index)"
                      :maxSize="2000000"
                      :value="item.filepath == undefined ? item : item.filepath"
                      :index="index"
                      :ref="'fileLookupImageAccept' + index"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            v-if="blnShowLookUpList == true && strDatatype != 8"
            class=""
            :class="device == 'mobile' ? 'mb-3' : ''"
          >
            <div class="d-flex w-100 mb-4">
              <div v-if="strDatatype == 1" class="d-flex gap-3 w-100">
                <InputText
                  :value="lookupvalue.strNameTH"
                  :formdata="lookupvalue.strNameTH"
                  @input="updateData('strNameTH', $event)"
                  :validate="'letterTH'"
                  :label="$t('444') + ' *'"
                  class="w-100"
                  :showTxtInp="iscandelete == undefined ? false : iscandelete"
                  alertMsg="419"
                  ref="strValueLookupText2"
                />
                <InputText
                  :value="lookupvalue.strNameEN"
                  :formdata="lookupvalue.strNameEN"
                  @input="updateData('strNameEN', $event)"
                  :label="$t('445') + ' *'"
                  class="w-100"
                  :validate="'letterEN'"
                  :showTxtInp="iscandelete == undefined ? false : iscandelete"
                  alertMsg="419"
                  ref="strValueLookupText1"
                />
              </div>
              <div v-if="strDatatype == 2" class="d-flex gap-3 w-100">
                <InputText
                  :value="lookupvalue.value"
                  :formdata="lookupvalue.value"
                  @input="updateData('value', $event)"
                  :validate="'numeric'"
                  :label="$t('121')"
                  class="w-100"
                  alertMsg="419"
                  ref="strDatatype2"
                />
              </div>
              <div v-if="strDatatype == 3" class="d-flex gap-3 w-100">
                <InputText
                  :value="lookupvalue.value"
                  :formdata="lookupvalue.value"
                  @input="updateData('value', $event)"
                  :validate="'currency'"
                  :label="$t('123')"
                  labelSub=": 1,000 *"
                  class="w-100"
                  :showTxtInp="iscandelete == undefined ? false : iscandelete"
                  alertMsg="419"
                  ref="strDatatype3"
                />
              </div>
              <div v-if="strDatatype == 5" class="d-flex gap-3 w-100">
                <inputTimeKey
                  :label="$t('140') + ' *'"
                  style="height: 90%"
                  @strValue="
                    (event) => {
                      lookupvalue['value'] = event;
                    }
                  "
                  :ValueHours="
                    lookupvalue.value == undefined
                      ? '00'
                      : lookupvalue.value.split(':')[0]
                  "
                  :ValueMinutesl="
                    lookupvalue.value == undefined
                      ? '00'
                      : lookupvalue.value.split(':')[1]
                  "
                  class="w-100"
                  alertMsg="419"
                  ref="strDatatype4"
                />
              </div>
              <div v-if="strDatatype == 4" class="d-flex gap-3 w-100">
                <inputCalendaSelecDateLength
                  :showFirstTime="false"
                  :showSeconTime="false"
                  :label="$t('139') + ' *'"
                  :labelFirstTime="$t('140')"
                  @input="
                    (event) => {
                      lookupvalue['value'] = event;
                    }
                  "
                  ref="strDatatype5"
                />
              </div>
              <div v-if="strDatatype == 6" class="d-flex gap-3 w-100">
                <inputCalendaSelecDateLength
                  :showFirstTime="true"
                  :showSeconTime="false"
                  :label="$t('146') + ' *'"
                  :labelFirstTime="$t('140')"
                  @input="
                    (event) => {
                      lookupvalue['value'] = event;
                    }
                  "
                  ref="strDatatype6"
                />
              </div>
              <div v-if="strDatatype == 7" class="d-flex gap-3 w-100">
                <InputText
                  :value="lookupvalue.value"
                  :formdata="lookupvalue.value"
                  @input="updateData('value', $event)"
                  :validate="'email'"
                  :label="$t('70') + ' *'"
                  class="w-100"
                  :showTxtInp="iscandelete == undefined ? false : iscandelete"
                  alertMsg="419"
                  ref="strDatatype7"
                />
              </div>

              <div v-if="strDatatype == 9" class="d-flex gap-3 w-100">
                <InputText
                  :value="lookupvalue.value"
                  :formdata="lookupvalue.value"
                  @input="updateData('value', $event)"
                  :validate="'phonenumber'"
                  :label="$t('69') + ' *'"
                  class="w-100"
                  :showTxtInp="iscandelete == undefined ? false : iscandelete"
                  alertMsg="419"
                  ref="strDatatype9"
                />
              </div>
              <Button
                v-if="strDatatype != 8"
                icon="AddFill"
                @input="addLookupText()"
                :classBtnIcon="
                  blnCheckDataAddLookUp == true
                    ? 'fill-black-400 '
                    : 'fill-secondary'
                "
                class="pt-2 mt-1 d-flex align-items-start"
                :class="blnCheckDataAddLookUp == true ? 'disable-submit' : ''"
              />
            </div>
          </div>
          <div class="">
            <Label
              v-if="blnShowLookUpList == true && blnclick == false"
              :label="strDatatype != 8 ? $t('834') : $t('374')"
              class="mb-3 fnt-medium fnt-subtitle"
            />
            <div v-if="blnShowLookUpList == true && strDatatype != 8">
              <div class="lookup-scrool" v-if="lookupList.length != 0">
                <template v-for="(item, index) in lookupList" :key="index">
                  <!-- <ImageList
                  v-if="strDatatype == 8"
                  @click="removeLookup(index)"
                  :name="item.name == undefined ? item.en : item.name"
                  iconLeft="RemoveFill"
                  iconClassLeft="fill-secondary"
                  :imgUrl="
                    typeof item.img == 'undefined' ? imgUrl.value : item.img
                  "
                /> -->
                  <!-- <List
                    @remove="remove(item)"
                    v-if="strDatatype != 8"
                    :name="
                      strDatatype == 1
                        ? item.strNameEN + ' : ' + item.strNameTH
                        : item.value
                    "
                    iconLeft="RemoveFill"
                    iconClassLeft="fill-secondary"
                  /> -->
                  <div class="d-flex align-items-center m-2 mt-3">
                    <span>{{ index + 1 }}</span>
                    <div v-if="strDatatype == 1" class="d-flex w-100">
                      <InputText
                        :label="
                          strDatatype == 1
                            ? $t('444') + ' *'
                            : listDropdrown[strDatatype - 1].lang
                        "
                        class="w-100 mx-3"
                        alertMsg="419"
                        :value="strDatatype == 1 ? item.strNameTH : item.value"
                        :validate="strDatatype == 1 ? 'letterTH' : ''"
                        @input="UpdateLookUpList($event, index, 'th')"
                        :ref="'inputTextLookUpListTH' + index"
                      />
                      <InputText
                        v-if="strDatatype == 1"
                        :label="$t('445') + ' *'"
                        class="w-100"
                        alertMsg="419"
                        :value="strDatatype == 1 ? item.strNameEN : item.value"
                        :validate="strDatatype == 1 ? 'letterEN' : ''"
                        @input="UpdateLookUpList($event, index, 'en')"
                        :ref="'inputTextLookUpListEN' + index"
                      />
                    </div>
                    <div v-if="strDatatype == 2" class="d-flex w-100">
                      <InputText
                        :label="$t('121') + ' *'"
                        class="w-100 ps-3"
                        alertMsg="419"
                        :value="item.value"
                        :validate="'numeric'"
                        @input="UpdateLookUpList($event, index)"
                        :ref="'inputTextLookUpListNumber' + index"
                      />
                    </div>
                    <div v-if="strDatatype == 3" class="d-flex w-100">
                      <InputText
                        :label="$t('123') + ' *'"
                        class="w-100 ps-3"
                        alertMsg="419"
                        :value="item.value"
                        :validate="'currency'"
                        :labelSub="': 1,000'"
                        @input="UpdateLookUpList($event, index)"
                        :ref="'inputTextLookUpListCurrency' + index"
                      />
                    </div>
                    <div v-if="strDatatype == 5" class="d-flex w-100">
                      <inputTimeKey
                        :label="$t('140') + ' *'"
                        class="w-100 ps-3"
                        style="height: 90%"
                        @strValue="UpdateLookUpList($event, index)"
                        :ValueHours="
                          item.value == undefined
                            ? ''
                            : item.value.split(':')[0]
                        "
                        :ValueMinutesl="
                          item.value == undefined
                            ? ''
                            : item.value.split(':')[1]
                        "
                        alertMsg="419"
                        :ref="'inputTextLookUpListTime' + index"
                      />
                    </div>
                    <div v-if="strDatatype == 4" class="d-flex w-100">
                      <inputCalendaSelecDateLength
                        class="ps-3"
                        :showFirstTime="false"
                        :showSeconTime="false"
                        :label="$t('139') + ' *'"
                        :labelFirstTime="$t('140')"
                        :valueCalenda="item.value"
                        @input="UpdateLookUpList($event, index)"
                        :ref="'inputTextLookUpListDate' + index"
                      />
                    </div>
                    <div v-if="strDatatype == 6" class="d-flex w-100">
                      <inputCalendaSelecDateLength
                        class="ps-3"
                        :showFirstTime="true"
                        :showSeconTime="false"
                        :label="$t('146') + ' *'"
                        :labelFirstTime="$t('140')"
                        :valueCalenda="item.value"
                        @input="UpdateLookUpList($event, index)"
                        :ref="'inputTextLookUpListDateTime' + index"
                      />
                    </div>
                    <div v-if="strDatatype == 7" class="d-flex w-100">
                      <InputText
                        :value="item.value"
                        :formdata="item.value"
                        @input="UpdateLookUpList($event, index)"
                        :validate="'email'"
                        :label="$t('70') + ' *'"
                        class="w-100 ps-3"
                        :showTxtInp="
                          iscandelete == undefined ? false : iscandelete
                        "
                        alertMsg="419"
                        :ref="'inputTextLookUpListEmail' + index"
                      />
                    </div>
                    <div v-if="strDatatype == 8" class="d-flex w-100">
                      <!-- imsge -->
                    </div>
                    <div v-if="strDatatype == 9" class="d-flex w-100">
                      <InputText
                        :value="item.value"
                        :formdata="item.value"
                        @input="UpdateLookUpList($event, index)"
                        validate="phonenumber"
                        :label="$t('69') + ' *'"
                        class="w-100 ps-3"
                        :showTxtInp="
                          iscandelete == undefined ? false : iscandelete
                        "
                        alertMsg="419"
                        :ref="'inputTextLookUpListPassword' + index"
                      />
                    </div>
                    <div>
                      <Button
                        icon="RemoveFill"
                        width="24"
                        height="24"
                        classBtnIcon="fill-secondary"
                        classBtn="px-2"
                        @input="remove(item, index)"
                      />
                    </div>
                  </div>
                </template>
              </div>
              <div v-if="lookupList.length == 0">
                <NotFound
                  :style="'height:fit-content; border: 0px solid !important;'"
                  class="border-bottom w-100 notfound"
                  name="834"
                />
              </div>
              <div class="mt-3" v-if="strDatatype != 8">
                <Button
                  class="btn btn-fix me-3"
                  :class="
                    blnCheckDataLookUp == true
                      ? 'disable-submit bg-black-300 text-white'
                      : 'btn-primary'
                  "
                  :name="$t('36')"
                  @input="AddLookUpList()"
                />
              </div>
            </div>
            <div v-if="blnShowLookUpList == false && strDatatype != 8">
              <!-- <Button
                @input="
                  () => {
                    blnShowLookUpList = !blnShowLookUpList;
                  }
                "
                icon="EditFill"
                classBtnIcon="fill-secondary"
                :name="$t('426', { fieldname: $t('รายการข้อมูล') })"
                :widthIcon="24"
                :heightIcon="24"
                className="text-secondary"
                class="d-flex align-items-center p-0 mb-2"
              /> -->
              <div class="ms-4">
                <div v-for="(item, index) in lookupList" :key="index">
                  <span class="me-1">{{ index + 1 }}{{ "." }}</span>
                  <span v-if="item.strNameTH != undefined">
                    {{
                      strDatatype == 1
                        ? item.strNameTH + " : " + item.strNameEN
                        : strDatatype == 5 || strDatatype == 6
                        ? convertNumberToDate(item.value)
                        : item.value
                    }}
                  </span>
                  <span v-if="item.nameTH != undefined">
                    {{
                      strDatatype == 1
                        ? item.nameTH + " : " + item.nameEN
                        : strDatatype == 5 || strDatatype == 6
                        ? convertNumberToDate(item.value)
                        : item.value
                    }}
                  </span>
                </div>
              </div>
            </div>
            <Button
              v-if="blnclick == false && strDatatype == 8"
              class="btn btn-fix me-3"
              @input="
                () => {
                  blnShowLookUpList = !blnShowLookUpList;
                }
              "
              @click="buttonChangeimage()"
              :class="
                blncheckDataImageLookup == true
                  ? 'disable-submit bg-black-300 text-white'
                  : 'btn-primary'
              "
              :name="$t('36')"
            />
            <!-- </div> -->
          </div>
          <div
            :class="alertMsgStatus == true ? '' : 'd-none'"
            class="d-flex alertInput align-items-center p-0 mt-1 fnt-body fnt-regular"
          >
            <Icon
              :class="alertMsgStatus == true ? '' : 'd-none'"
              icon="carbon:warning"
              class="me-1"
            />
            {{
              $t("202", {
                fieldname: " " + $t("137").toLowerCase(),
              })
            }}
          </div>
        </div>
      </div>
    </div>

    <Label
      classBtn="tooltiper ms-1 text-black-500"
      classBtnIcon="fill-black-500"
      :tooltipMsg="$t('353')"
      tooltipPlacement="tooltip-top"
      icon="QuestionOutline"
      :widthIcon="16"
      :heightIcon="16"
      :helper="true"
      :label="$t('347')"
      labelclassed="fnt-medium fnt-subtitle"
      class="my-4"
    />
    <!-- Label upload icon -->
    <InputFile
      size="sizeSmall"
      name="Image"
      subname="SVG"
      :helper="$t('374')"
      datavalue="strIcon"
      maxSize="2000000"
      widthicon="32"
      heighticon="32"
      :value="formdata.strIcon"
      @input="updateFieldname('strIcon', $event)"
      :filetype="'image/svg+xml'"
    />

    <div class="mt-6 d-flex">
      <!-- <a :href="'#' + ScrollRepeats"> -->
      <Button
        @click="detectalertDataDuplicate(msgalertDataDuplicate)"
        class="btn btn-fix me-3"
        :class="
          blnCheckData == true
            ? 'disable-submit bg-black-300 text-white'
            : 'btn-primary'
        "
        :name="$t('36')"
      />
      <!-- </a> -->

      <Button
        @click="closeForm()"
        class="btn-cancel btn-fix text-primary"
        :name="$t('35')"
      />
    </div>
    <div class="w-100 mt-4" v-if="inactive !== true">
      <DeleteInform
        icon="DeleteFill"
        classBtnIcon="fill-secondary "
        class="mt-5"
        @input="deleteData()"
      />
    </div>
  </div>
  <!-- <pre class="w-25">{{ intOldTypeTextdisplay }}</pre> -->
  <!-- <pre class="w-25">{{ UserFielddata }}</pre> -->
  <!-- <pre class="w-25">{{ formdata }}</pre> -->
  <!-- <pre class="w-25">{{ listSrcImage[0].filepath.split('/')[listSrcImage[0].filepath.split('/').length-1] }}</pre> -->
  <!-- <pre class="w-25">{{ Listfile }}</pre> -->
</template>

<script>
import DeleteInform from "@/components/button/deleteinform.vue";
import Longtext from "@/components/input/inputLongText.vue";
import Radio from "../input/radio.vue";
import Button from "../button/button.vue";
import SelectDropdown from "../input/selectDropdown.vue";
import Checkbox from "../input/checkbox.vue";
import InputText from "../input/inputText.vue";
//import InputNumber from "@/components/input/inputNumber.vue";
import Label from "../label/label.vue";
import InputFile from "../../components/input/inputFile.vue";
import AlertPopup from "@/components/alert/alertPopup.vue";
import DropdownList from "@/components/input/dropdown/select.vue";
import File from "@/components/input/inputFileuserfield.vue";
import NotFound from "../notFound/notFound.vue";
import inputTimeKey from "@/components/input/inputTimeKey.vue";
import inputCalendaSelecDateLength from "../input/inputCalendaSelecDateLength.vue";

import { Icon } from "@iconify/vue";
import Enumerable from "linq";

// import { Icon } from "@iconify/vue";
// import InputFileComponent from "../input/inputFileComponent.vue";
// import ImageList from "../list/imageList.vue";
export default {
  props: {
    action: { type: String },
    formdata: { type: Array },
    inactive: { type: Boolean, default: false },
    iscandelete: { type: Boolean, default: false },
    classList: { type: String },
    index: { type: Number },
    ScrollRepeat: { type: String },
  },
  data() {
    return {
      intNotemptylength: 0,
      blnclick: false,
      Listfile: [
        { strname: "", src: "" },
        { strname: "", src: "" },
        { strname: "", src: "" },
        { strname: "", src: "" },
        { strname: "", src: "" },
        { strname: "", src: "" },
        { strname: "", src: "" },
        { strname: "", src: "" },
        { strname: "", src: "" },
        { strname: "", src: "" },
        { strname: "", src: "" },
        { strname: "", src: "" },
      ],
      Listfileupload: [],
      imgsrc: "",
      filetype: "",
      // inactive: false,
      blnminYear: false,
      blnChangeDatatype: false,
      blnChangeFieldtype: false,
      blnCloseForm: false,
      alertMsgStatus: false,
      alertMsgInput: null,
      UserFielddata: {
        strFieldName: "",
        intFieldType: 0,
        // strReference: "",
        strIconName: "",
        strDatatype: "1",
        strValue: "",
        strImageValue: "",
        strOldImageValue: "",
        intRequire: 0,
        intIdentifier: 0,
        intRepeatable: 0,
        intNotnull: 0,
        strFieldnameth: "",
        intSearchflag: 0,
        strLookupValue: "",
        strImageLookupValue: [],
        listImageLookup: "",
        intLangcontrolst: 0,
        intTemplateDifinest: 0,
        strIcon: "",
        strFileID: [], //เก็บไอดีที่ลบ
        strLabelTH: "",
        strLabelEN: "",
        intDisplayType: 0,
      },
      loading: true,
      emits: ["onInput", "Insert", "Update"],
      listSearch: [],
      listDataSelected: { tpye: Array },
      selectdatatype: "Text",
      listActiveFilters: [],
      SelectedValue: [],
      activeFiltersSelected: [],
      ListDummy: {},
      fieldtype: Number,
      stroldFieldtype: 0,
      strDatatype: 0,
      stroldDatatype: 1,
      lookuplist: [],
      lookupImg: {},
      lookupvalue: {},
      optionList: [
        {
          name: "134", // not null
          checked: false,
        },
        {
          name: "133", // repeatable
          checked: false,
        },
        {
          name: "831",
          checked: false,
        },
      ],

      radioList: [
        {
          id: 1,
          name: "120", //Text
        },
        {
          id: 2,
          name: "121", //Number
        },
        {
          id: 3,
          name: "123", //Currency
        },
        {
          id: 4,
          name: "140", //Time
        },
        {
          id: 5,
          name: "139", //Date
        },
        {
          id: 6,
          name: "146", //Date & Time
        },
        {
          id: 7,
          name: "70", //Email
        },
        {
          id: 8,
          name: "203", //Image
        },
        {
          id: 9,
          name: "Password", //Password
        },
      ],
      listDropdrownComponent: [
        {
          id: 1,
          lang: "1016", //ดรอปดาวน์แบบค้นหา
        },
        {
          id: 2,
          lang: "1017", //ดรอปดาวน์หลายตัวเลือกแบบค้นหา
        },
        {
          id: 3,
          lang: "1018", //ดรอปดาวน์แบบไม่มีการค้น
        },
        {
          id: 4,
          lang: "1019", //ดรอปดาวน์หลายตัวเลือกแบบไม่มีการค้น
        },
        {
          id: 5,
          lang: "1020", //เลือกแบบหลายตัวเลือก
        },
        {
          id: 6,
          lang: "1021", //เลือกแบบตัวเลือกเดียว
        },
      ],
      listDropdrown: [
        {
          id: 1,
          lang: "120", //Text
        },
        {
          id: 2,
          lang: "121", //Number
        },
        {
          id: 3,
          lang: "123", //Currency
        },
        {
          id: 4,
          lang: "139", //Date
        },
        {
          id: 5,
          lang: "140", //Time
        },
        {
          id: 6,
          lang: "146", //Date & Time
        },
        {
          id: 7,
          lang: "70", //Email
        },
        {
          id: 8,
          lang: "203", //Image
        },
        {
          id: 9,
          lang: "69", //Phone
        },
      ],
      listRadioLang: [
        {
          id: 0,
          name: "878", //ไทย/อังกฤษ
        },
        {
          id: 1,
          name: "837", //ภาษาไทย
        },
        {
          id: 2,
          name: "838", //ภาษาอังกฤษ
        },
      ],
      listRadioFieldtype: [
        {
          id: 3,
          name: "135", // Variable
        },
        {
          id: 2,
          name: "138", //Fixed
        },

        {
          id: 4,
          name: "137",
          //Lookup
        },
      ],
      imgUrl: {},
      processdata: false,
      blnShowLookUpList: true,
      blnAdddata: false,
      listLengthImage: [],
      listSrcImage: [],
      listDummyImgLookup: [],
      listIndexImage: [],
      month_names_th: [
        "ม.ค.",
        "ก.พ.",
        "มี.ค.",
        "เม.ย.",
        "พ.ค.",
        "ม.ย.",
        "ก.ค.",
        "ส.ค.",
        "ก.ย.",
        "ต.ค.",
        "พ.ย.",
        "ธ.ค.",
      ],
      month_names_en: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      listIDimgRemove: [],
      srcImgFixed: "",
      liststrImgID: [],
      listImgData: [],
      intOldTypeTextdisplay: 1,
    };
  },
  mounted() {
    // 21/03/66 เพิ่มรับค่าจาก formdata ใส่ตัวแปร
    // - stroldDatatype
    // - stroldFieldtype
    if (this.action == "Update") {
      // console.log("Update");
      this.UserFielddata.intFieldType = this.formdata.intFieldType;
      // this.FieldType(this.UserFielddata.intFieldType);

      setTimeout(() => {
        this.UserFielddata.strFieldName = this.formdata.strFieldName;

        this.UserFielddata.strDatatype = this.formdata.strDatatype;
        this.stroldDatatype = this.formdata.strDatatype;
        this.strDatatype = this.formdata.strDatatype;

        this.UserFielddata.intRequire = this.formdata.intRequire;
        this.UserFielddata.intIdentifier = this.formdata.intIdentifier;
        this.UserFielddata.intRepeatable = this.formdata.intRepeatable;
        this.UserFielddata.intNotnull = this.formdata.intNotnull;
        this.UserFielddata.intTemplateDifinest =
          this.formdata.intTemplateDifinest;
        this.UserFielddata.intLangcontrolst = this.formdata.intLangcontrolst;
        this.UserFielddata.strFieldnameth = this.formdata.strFieldnameth;
        this.UserFielddata.intSearchflag = this.formdata.intSearchflag;
        this.UserFielddata.strIcon = this.formdata.strIcon;
        this.UserFielddata.strIconName = this.formdata.strIconName;
        this.UserFielddata.strImageValue = this.formdata.strImageValue;
        this.UserFielddata.strValue = this.formdata.strValue;
        this.UserFielddata.strOldImageValue = this.formdata.strValue;
        this.UserFielddata.strLookupValue = this.formdata.strLookupValue;
        this.UserFielddata.intDisplayType = this.formdata.intDisplayType;
        if (this.formdata.strLookupValue == "") {
          this.UserFielddata.strLookupValue = "";
        }
        this.UserFielddata.listImageLookup = this.formdata.listImageLookup;
        this.listImgData.push(...this.formdata.listImageLookup);
        this.UserFielddata.strImageLookupValue =
          this.formdata.strImageLookupValue;
        this.UserFielddata.strFileID = this.formdata.strFileID;
        // this.UserFielddata.Lookuppath = this.formdata.listImageLookup;
        this.UserFielddata.strLabelTH = this.formdata.strLabelTH;
        this.UserFielddata.strLabelEN = this.formdata.strLabelEN;
        if (this.formdata.listImageLookup.length > 0) {
          this.blnclick = true;
        } else {
          this.blnclick = false;
        }

        this.listSrcImage = this.formdata.listImageLookup;
        this.fieldtype = this.formdata.intFieldType;
        this.stroldFieldtype = this.formdata.intFieldType;
        this.intNotemptylength = this.formdata.listImageLookup;
        this.listDummyImgLookup = this.formdata.listImageLookup;
        this.blnChangeFieldtype = false;
        this.blnChangeDatatype = false;
        if (this.formdata.intFieldType == 4) {
          this.blnShowLookUpList = false;
        }
        if (this.UserFielddata.intRepeatable == 1) {
          setTimeout(() => {
            this.blnChangeFieldtype = false;
          }, 10);
        }
        this.optionList[0].checked =
          this.formdata.intNotnull == 1 ? true : false;
        this.optionList[1].checked =
          this.formdata.intRepeatable == 1 ? true : false;
        this.optionList[2].checked =
          this.formdata.intTemplateDifinest == 1 ? true : false;
        this.listDataSelected = this.listSearch.filter((ele) => {
          // console.log(ele);
          return ele.id == this.UserFielddata.intFieldType;
        });

        if (this.formdata.strLookupValue !== "") {
          this.UserFielddata.strLookupValue = JSON.parse(
            this.formdata.strLookupValue
          );

          // console.log(this.UserFielddata.strLookupValue);
        } else {
          this.UserFielddata.strLookupValue = this.formdata.strLookupValue;
        }

        if (this.UserFielddata.intIdentifier == 1) {
          this.$refs["option2"][0].blnChange = true;

          this.listSearch = [
            {
              id: 3,
              lang: "135", // Variable
            },
          ];
          // console.log(this.$refs.SelectDropdown);
          this.listSearch.filter((ele, index) => {
            if (ele.id == this.UserFielddata.intFieldType) {
              this.$refs.SelectDropdown.onSeletction(this.listSearch[index]);
            }
          });

          // setTimeout(() => {
          //   this.FieldType(2);
          // }, 10);
          setTimeout(() => {
            this.UserFielddata.strDatatype = this.formdata.strDatatype;
            // this.strDatatype = this.formdata.strDatatype;
          }, 10);
        }
        if (this.UserFielddata.intRepeatable == 0) {
          this.listSearch = [
            {
              id: 3,
              lang: "135", // Variable
            },
          ];
          // console.log(this.$refs.SelectDropdown);
          this.listSearch.filter((ele, index) => {
            if (ele.id == this.UserFielddata.intFieldType) {
              this.$refs.SelectDropdown.onSeletction(this.listSearch[index]);
            }
          });
          // setTimeout(() => {
          //   this.FieldType(3);
          // }, 10);

          setTimeout(() => {
            this.UserFielddata.strDatatype = this.formdata.strDatatype;
            this.strDatatype = this.formdata.strDatatype;
          }, 10);
        } else {
          this.listSearch = [
            {
              id: 2,
              lang: "138", //Fixed
            },
            {
              id: 3,
              lang: "135", // Variable
            },
            {
              id: 4,
              lang: "137",
              //Lookup
            },
          ];
          this.listSearch.filter((ele, index) => {
            if (ele.id == this.UserFielddata.intFieldType) {
              // console.log(index);
              this.$refs.SelectDropdown.onSeletction(this.listSearch[index]);
            }
          });

          // setTimeout(() => {
          //   this.FieldType(this.UserFielddata.intFieldType);
          // }, 10);
          setTimeout(() => {
            this.UserFielddata.strDatatype = this.formdata.strDatatype;
            this.strDatatype = this.formdata.strDatatype;
          }, 10);
        }

        // console.log(this.optionList);
        this.processdata = true;
        // console.log(this.strDatatype);
        // console.log(this.listSearch);
        this.UserFielddata.strValue = this.formdata.strValue;
      }, 10);
      this.UserFielddata.strValue = this.formdata.strValue;
      setTimeout(() => {
        if ( this.blnChangeFieldtype == true ) {
          this.confirmselectFieldType(false);
        } 
      }, 50);
      
      // console.log(this.UserFielddata.strValue);
    } else if (this.action == "Insert") {
      this.listSearch = [
        // {
        //   id: 2,
        //   lang: "138", //Fixed
        // },
        {
          id: 3,
          lang: "135", // Variable
        },
        // {
        //   id: 4,
        //   lang: "137",
        //   //Lookup
        // },
      ];
      this.processdata = true;
      // console.log(this.$refs["SelectDropdown"]);

      setTimeout(() => {
        this.FieldType(3);
        this.stroldFieldtype = 3;
        this.$refs.SelectDropdown.reset();
      }, 100);
    }
  },
  computed: {
    lookupList() {
      // console.log(this.UserFielddata.strLookupValue);
      // console.log(typeof this.UserFielddata.strLookupValue);
      return this.UserFielddata.strLookupValue;
    },
    optionListRender() {
      // console.log(this.optionList);
      return this.optionList;
    },
    ListSearch() {
      return this.listSearch;
    },
    blnCheckData() {
      return this.checkDataNotnull();
    },
    blnCheckDataLookUp() {
      return this.checkDataLookUp();
    },
    blnCheckDataAddLookUp() {
      return this.CheckDataAddLookUp();
    },
    listLookUpImage() {
      return this.Listfile;
    },
    blncheckDataImageLookup() {
      return this.checkDataImageLookup();
    },
    ScrollRepeats() {
      return this.ScrollRepeat;
    },
  },
  components: {
    File,
    Label,
    InputText,
    Checkbox,
    SelectDropdown,
    Button,
    Radio,
    //InputNumber,
    // TimeInput,
    // DateInput,
    // DateTimeInput,
    // List,
    InputFile,
    DeleteInform,
    Icon,
    AlertPopup,
    Longtext,
    DropdownList,
    NotFound,
    inputTimeKey,
    inputCalendaSelecDateLength,
  },
  methods: {
    confirmaddData(state) {
      if (state == true) {
        this.addData();
        this.$emit("confirm", state);
      } else {
        this.blnAdddata = false;
      }
    },
    filedata(e, index) {
      // this.Listfile.splice(index, 1, { filepath: "", fileID: "", image: e });
      const isDuplicate = Enumerable.from(this.Listfile).any(
        (w) => w.image !== undefined && w.image.name === e.name
      );
      console.log(isDuplicate);
      // console.log(this.action);
      // เตรียมส่งข้อมูล
      if (this.action == "Insert") {
        this.Listfile.splice(index, 1, { filepath: "", fileID: "", image: e });
        this.intNotemptylength = this.Listfile.filter(
          (item) => item.filepath !== undefined
        ).length;
        // console.log(this.intNotemptylength);

        if (this.intNotemptylength >= 2) {
          if (index == 0) {
            this.$refs.file[index + 2].classList.toggle("disabled", false);
          } else if (index != 11) {
            this.$refs.file[index + 1].classList.toggle("disabled", false);
          }
        }

        this.UserFielddata.strImageLookupValue.splice(index, 1, e);
        // console.log(this.UserFielddata.strImageLookupValue);
        this.listDummyImgLookup = this.UserFielddata.strImageLookupValue;
      } else if (this.action == "Update") {
        // console.log(this.listIndexImage);
        // console.log(index);
        let id = this.listIndexImage.filter((ele) => {
          return ele.index == index;
        });
        // console.log(id);
        var reader = new FileReader();
        let base64;
        let base64Forshow;
        reader.readAsDataURL(e);
        reader.onload = () => {
          base64Forshow = reader.result;
          base64 = reader.result.split(",")[1];
        };

        setTimeout(() => {
          if (id.length == 0) {
            this.Listfile.splice(index, 1, {
              FileName: e.name,
              FormFiles: base64Forshow,
              fileID: 0,
            });
            // อัพรูปไปใหม่
            this.UserFielddata.listImageLookup.splice(index, 1, {
              FileName: e.name,
              FormFiles: base64,
              fileID: 0,
            });
          } else if (id.length > 0) {
            this.Listfile.splice(index, 1, {
              FileName: e.name,
              FormFiles: base64Forshow,
              fileID: id.length == 0 ? 0 : id[0].fileID.toString(),
            });
            // อัพเดทรูป
            this.UserFielddata.listImageLookup.splice(
              index,
              1,
              //   filepath: "",
              //   fileID: id.length == 0 ? "" : id[0].fileID,
              //   image: e,
              // }
              {
                FileName: e.name,

                FormFiles: base64,
                fileID: id.length == 0 ? "" : id[0].fileID.toString(),
                // fileID: "",
              }
            );
          }

          // this.intNotemptylength = this.Listfile.filter(
          //   (item) => item.filepath !== undefined
          // ).length;
          // console.log(this.intNotemptylength);

          // if (this.intNotemptylength >= 2) {
          //   if (index == 0) {
          //     this.$refs.file[index + 2].classList.toggle("disabled", false);
          //   } else if (index != 11) {
          //     this.$refs.file[index + 1].classList.toggle("disabled", false);
          //   }
          // }
          this.listDummyImgLookup = this.UserFielddata.listImageLookup;
        }, 500);
      }
      setTimeout(() => {
        this.listLengthImage = [];
        for (let index = 0; index < this.Listfile.length; index++) {
          if (
            this.Listfile[index].FileName != undefined ||
            this.Listfile[index].filepath != undefined
          ) {
            this.listLengthImage.push(this.Listfile[index]);
          }
        }

        // สำหรับ disable ปุ่ม ok
        let listintNotemptylength = [];
        for (let index = 0; index < this.Listfile.length; index++) {
          if (
            this.Listfile[index].FileName != undefined ||
            (this.Listfile[index].filepath != undefined &&
              this.Listfile[index].filepath != "")
          ) {
            listintNotemptylength.push(this.Listfile[index]);
          }
        }
        // this.intNotemptylength = this.Listfile.filter(
        //   (item) => item.FormFiles !== undefined || item.FileName !== undefined
        // ).length;
        // console.log(this.intNotemptylength);

        this.intNotemptylength = listintNotemptylength.length;
        if (this.intNotemptylength >= 2) {
          if (index == 0) {
            this.$refs.file[index + 2].classList.toggle("disabled", false);
          } else if (index != 11) {
            this.$refs.file[index + 1].classList.toggle("disabled", false);
          }
        }
      }, 500);

      // clear alert inputImg
      for (let j = 0; j < this.Listfile.length; j++) {
        this.$refs["fileLookupImage" + j][0].alertMsgStatus = false;
        this.$refs["fileLookupImage" + j][0].msgError = "";
      }
    },

    removedata(e, index, fileID) {
      if (this.UserFielddata.intFieldType == 4) {
        // console.log(fileID);
        this.Listfile.splice(e, 1, { filepath: "", fileID: "", image: e });

        if (index != 0 && this.Listfile[index + 1] == undefined) {
          this.$refs.file[index + 1].classList.toggle("disabled");
        }

        // เตรียมส่งข้อมูล
        // console.log(e);
        if (this.action == "Insert") {
          this.UserFielddata.strImageLookupValue.splice(index, 1, null);
          this.listDummyImgLookup = this.UserFielddata.strImageLookupValue;
          this.listDummyImgLookup = this.listDummyImgLookup.filter((ele) => {
            return ele != null;
          });
          // console.log(this.UserFielddata.strImageLookupValue);
        } else if (this.action == "Update") {
          this.UserFielddata.listImageLookup.splice(index, 1, null);
          this.listDummyImgLookup = this.UserFielddata.listImageLookup;
          this.listDummyImgLookup = this.listDummyImgLookup.filter((ele) => {
            return ele != null;
          });

          if (fileID != "") {
            // เก็บ file id ที่ลบ
            const index = this.listIDimgRemove.indexOf(parseInt(fileID));
            if (index > -1) {
              this.listIDimgRemove.splice(index, 1);
            } else {
              this.listIDimgRemove.push(parseInt(fileID));
            }
          }
          this.listIndexImage.push({ index: index, fileID: fileID });
        }
        // console.log(this.listDummyImgLookup);

        // สำหรับ disable ปุ่ม ok
        let listintNotemptylength = [];
        for (let index = 0; index < this.Listfile.length; index++) {
          if (
            this.Listfile[index].FileName != undefined ||
            (this.Listfile[index].filepath != undefined &&
              this.Listfile[index].filepath != "")
          ) {
            listintNotemptylength.push(this.Listfile[index]);
          }
        }

        this.intNotemptylength = listintNotemptylength.length;
        if (this.intNotemptylength >= 2) {
          if (index == 0) {
            this.$refs.file[index + 2].classList.toggle("disabled", false);
          } else if (index != 11) {
            this.$refs.file[index + 1].classList.toggle("disabled", false);
          }
        }

        // clear alert inputImg
        for (let j = 0; j < this.Listfile.length; j++) {
          this.$refs["fileLookupImage" + j][0].alertMsgStatus = false;
          this.$refs["fileLookupImage" + j][0].msgError = "";
        }
      }
    },
    checkDuplicateImage() {
      // ปิด alert input ทั้งหมดก่อน
      for (let j = 0; j < this.Listfile.length; j++) {
        this.$refs["fileLookupImage" + j][0].alertMsgStatus = false;
        this.$refs["fileLookupImage" + j][0].msgError = "";
      }
      // เปิด alert input ที่ซ้ำ
      if (this.action == "Insert") {
        for (let i = 0; i < this.Listfile.length; i++) {
          const element = this.Listfile[i];
          const ImgDuplicate = this.Listfile.filter((e) => {
            if (
              e != null &&
              element != null &&
              e.image != undefined &&
              element.image != undefined &&
              e.image.name != undefined &&
              element.image.name != undefined
            ) {
              return e.image.name == element.image.name;
            }
          });
          // console.log(ImgDuplicate);
          if (ImgDuplicate.length > 1) {
            this.blnShowLookUpList = true;
            this.blnclick = false;
            this.$refs.dropdownDatatype4.inactive = false;
            this.$refs["fileLookupImage" + i][0].alertMsgStatus = true;
            this.$refs["fileLookupImage" + i][0].msgError = this.$t("903");
          }
        }
      } else {
        if (this.action == "Update") {
          let listImgNameFromApi = [];
          // let listImgNameUser = [];
          let ImgDuplicateApi = [];
          let ImgDuplicate = [];
          let indexImgFromAPI = [];
          for (let i = 0; i < this.Listfile.length; i++) {
            const element = this.Listfile[i];

            // เก็บชื่อรูปที่ได้จาก API
            if (element.filepath != undefined && element.filepath != "") {
              let lengthID = element.fileID.toString().length;
              let indexImg = element.filepath.split("/").length - 1;
              let ImgName = element.filepath.split("/")[indexImg].slice(lengthID);
              listImgNameFromApi.push(ImgName);
            }

            // เก็บชื่อรูปไปใหม่โดย User
            // if (element.FileName != undefined) {
            //   listImgNameUser.push(element.FileName);
            // }
            // เช็คซ้ำรูปที่ User เพิ่มมาเอง
            ImgDuplicate = this.Listfile.filter((e) => {
              if (
                e.FileName != undefined &&
                element.FileName != undefined &&
                e.FileName != "" &&
                element.FileName != ""
              ) {
                return e.FileName == element.FileName;
              }
            });
            if (ImgDuplicate.length > 1) {
              this.blnShowLookUpList = true;
              this.blnclick = false;
              this.$refs.dropdownDatatype4.inactive = false;
              this.$refs["fileLookupImage" + i][0].alertMsgStatus = true;
              this.$refs["fileLookupImage" + i][0].msgError = this.$t("903");
            }

            // เช็คซ้ำรูปที่เพิ่มมาโดย User กับ API
            ImgDuplicateApi = listImgNameFromApi.filter((e) => {
              if (e != undefined && element.FileName != undefined) {
                return e == element.FileName;
              }
            });
            // console.log(listImgNameFromApi);

            // ตรวจสอบว่ารูปใดที่มาจากAPI ที่ซ้ำ
            ImgDuplicateApi.forEach((e) => {
              listImgNameFromApi.forEach((ele, index) => {
                if (ele == e) {
                  indexImgFromAPI.push(index);
                }
              });
            });

            // แจ้งเตือนรูปซ้ำที่มาจากAPI
            if (indexImgFromAPI.length > 0) {
              indexImgFromAPI.forEach((e) => {
                this.$refs["fileLookupImage" + e][0].alertMsgStatus = true;
                this.$refs["fileLookupImage" + e][0].msgError = this.$t("903");
              });
            }

            // console.log(ImgDuplicateApi);
            // แจ้งเตือนรูปซ้ำที่มาจากAPI กับที่เพิ่มไปใหม่
            if (ImgDuplicateApi.length > 0) {
              this.blnShowLookUpList = true;
              this.blnclick = false;
              this.$refs.dropdownDatatype4.inactive = false;
              this.$refs["fileLookupImage" + i][0].alertMsgStatus = true;
              this.$refs["fileLookupImage" + i][0].msgError = this.$t("903");
            }
            // console.log(ImgDuplicate);
            // แจ้งเตือนรูปซ้ำที่เพิ่มไปใหม่ กับที่เพิ่มไปใหม่
            // if (ImgDuplicate.length > 1) {
            //   console.log(i);
            //   this.blnShowLookUpList = true;
            //   this.blnclick = false;
            //   this.$refs.dropdownDatatype4.inactive = false;
            //   this.$refs["fileLookupImage" + i][0].alertMsgStatus = true;
            //   this.$refs["fileLookupImage" + i][0].msgError = this.$t("903");
            // }
            // console.log(listImgName);
          }
        }
      }
    },
    buttonChangeimage() {
      this.blnclick = true;
      this.blnShowLookUpList = false;
      this.$refs.dropdownDatatype4.inactive = true;

      // console.log(this.Listfile);
      this.listLengthImage = [];
      this.listSrcImage = [];
      for (let index = 0; index < this.Listfile.length; index++) {
        if (
          this.Listfile[index].FileName != undefined ||
          this.Listfile[index].filepath != undefined
        ) {
          this.listLengthImage.push(this.Listfile[index]);
        }
        if (this.action == "Insert") {
          this.Listfile[index].src =
            this.$refs["fileLookupImage" + index][0].image;
          this.listSrcImage.push(
            this.$refs["fileLookupImage" + index][0].image
          );
          this.listSrcImage = this.listSrcImage.filter((ele) => {
            return ele != "";
          });
        } else if (this.action == "Update") {
          this.listSrcImage.push(
            this.$refs["fileLookupImage" + index][0].image
          );
          this.listSrcImage = this.listSrcImage.filter((ele) => {
            return ele != "";
          });
        }
      }
      this.listSrcImage = this.listSrcImage.sort((a, b) => {
        if (a === "") {
          return 1; // move undefined to end
        } else if (b === "") {
          return -1; // move undefined to beginning
        } else {
          return a - b; // sort numbers in ascending order
        }
      });
      // console.log(this.listSrcImage);

      // เตรียมส่งข้อมูล
      if (this.action == "Insert") {
        this.UserFielddata.strImageLookupValue =
          this.UserFielddata.strImageLookupValue.filter((ele) => {
            return ele != null;
          });

        setTimeout(() => {
          if (this.UserFielddata.strImageLookupValue.length < 2) {
            this.blnclick = false;
          }
        }, 500);
      } else if (this.action == "Update") {
        // this.UserFielddata.listImageLookup =
        //   this.UserFielddata.listImageLookup.filter((ele) => {
        //     return ele != null;
        //   });

        // ID รูปที่ลบ
        for (let i = 0; i < this.UserFielddata.listImageLookup.length; i++) {
          const element = this.UserFielddata.listImageLookup[i];
          if (element != null) {
            if (element.filepath != undefined && element.fileID != undefined) {
              if (element.filepath == "" && element.fileID == "") {
                this.UserFielddata.listImageLookup[i] = null;
              }
            }
            const index = this.listIDimgRemove.indexOf(
              parseInt(element.fileID)
            );
            if (index > -1) {
              this.listIDimgRemove.splice(index, 1);
            }
          }
        }
        this.UserFielddata.strFileID = [];
        this.UserFielddata.strFileID = this.listIDimgRemove;
        // console.log(this.liststrImgID);
        if (this.liststrImgID.length > 0) {
          this.UserFielddata.strFileID.push(...this.liststrImgID);
          this.UserFielddata.strFileID = this.liststrImgID;
          // console.log(this.UserFielddata);
        }

        setTimeout(() => {
          if (this.UserFielddata.listImageLookup.length < 2) {
            this.blnclick = false;
          }
        }, 500);

        // this.UserFielddata.Lookuppath = this.UserFielddata.Lookuppath.filter(
        //   (ele) => {
        //     return ele != null;
        //   }
        // );

        // setTimeout(() => {
        //   if (this.UserFielddata.Lookuppath.length < 2) {
        //     this.blnclick = false;
        //   }
        // }, 500);
      }

      this.checkDuplicateImage();
    },
    editimagebutton() {
      this.$refs.dropdownDatatype4.inactive = false;
      this.blnShowLookUpList = true;
      // this.Listfile.sort((a, b) => {
      //   if (a === undefined) {
      //     return 1; // move undefined to end
      //   } else if (b === undefined) {
      //     return -1; // move undefined to beginning
      //   } else {
      //     return a - b; // sort numbers in ascending order
      //   }
      // });
      // this.Listfile.sort((a, b) => b.src.localeCompare(a.src));
      this.Listfile = this.Listfile.filter((ele) => {
        return ele.strname != "";
      });
      // console.log(this.Listfile);
      // let count = 12 - this.Listfile.length;
      // for (let index = 0; index < count; index++) {
      //   this.Listfile.push({ strname: "", src: "" });
      // }
      if (this.action == "Insert") {
        let count = 12 - this.Listfile.length;
        for (let index = 0; index < count; index++) {
          this.Listfile.push({ strname: "", src: "" });
        }
      }
      if (this.action == "Update") {
        this.Listfile = this.Listfile.filter((ele) => {
          return ele.strname != "";
        });
        let oldListfile = this.Listfile;
        // console.log(oldListfile);
        // this.listLengthImage = this.formdata.listImageLookup;
        // this.Listfile = this.formdata.listImageLookup;
        this.listLengthImage = oldListfile;
        this.Listfile = oldListfile;
        // console.log(this.listLengthImage);
        // console.log(this.Listfile);
        if (this.Listfile.length <= 0) {
          this.Listfile = this.listDummyImgLookup;
        }
        let count = 12 - this.Listfile.length;
        for (let index = 0; index < count; index++) {
          this.Listfile.push({ strname: "", src: "" });
        }

        // if (this.action == "Insert") {
        //   this.listLengthImage = this.listLengthImage.filter((ele) => {
        //     return ele != undefined;
        //   });
        //   this.UserFielddata.listImageLookup =
        //     this.UserFielddata.listImageLookup.filter((ele) => {
        //       return ele != undefined;
        //     });
        //   this.listSrcImage = this.listSrcImage.filter((ele) => {
        //     return ele != undefined;
        //   });
        //   this.intNotemptylength = this.intNotemptylength.filter((ele) => {
        //     return ele != null;
        //   });
        //   this.listDummyImgLookup = this.listDummyImgLookup.filter((ele) => {
        //     return ele != null;
        //   });
        // }
        // if (this.action == "Update") {
        this.listLengthImage = this.listLengthImage.filter((ele) => {
          return ele.strname != "" && ele.filepath != "";
        });
        this.UserFielddata.listImageLookup = this.Listfile;
        this.UserFielddata.listImageLookup =
          this.UserFielddata.listImageLookup.filter((ele) => {
            return ele.filepath != undefined || ele.FileName != undefined;
          });
        this.listSrcImage = this.listSrcImage.filter((ele) => {
          return ele.filepath != undefined || ele.FileName != undefined;
        });
        if (typeof this.intNotemptylength == "object") {
          this.intNotemptylength = this.intNotemptylength.filter((ele) => {
            return ele.filepath != undefined || ele.FileName != undefined;
          });
        }
        // console.log(this.listDummyImgLookup);
        this.listDummyImgLookup = this.listDummyImgLookup.filter((ele) => {
          if (ele != null) {
            return ele.filepath != undefined || ele.FileName != undefined;
          }
        });
        if (this.listLengthImage.length <= 0) {
          this.listLengthImage = this.listDummyImgLookup;
        }
        // }
      }
      // console.log(this.listLengthImage);
      // console.log(this.formdata);
      this.blnclick = false;
    },
    confirmselectDataType(state) {
      // console.log(state);
      if (state == true) {
        this.lookupImg = [];
        this.lookuplist = [];
        this.lookupvalue = {};
        this.UserFielddata.strLookupValue = [];
        this.UserFielddata.strFileID = [];
        this.UserFielddata.intDisplayType = 1;
        if (this.UserFielddata.listImageLookup.length > 0) {
          for (let i = 0; i < this.listImgData.length; i++) {
            const element = this.listImgData[i];
            if (
              element != null &&
              element.fileID != null &&
              element.fileID != ""
            ) {
              this.UserFielddata.strFileID.push(element.fileID);
            }

            this.liststrImgID = [];
            this.liststrImgID = this.UserFielddata.strFileID;
          }
        }
        this.listLengthImage = [];
        this.Listfile = [
          { strname: "", src: "" },
          { strname: "", src: "" },
          { strname: "", src: "" },
          { strname: "", src: "" },
          { strname: "", src: "" },
          { strname: "", src: "" },
          { strname: "", src: "" },
          { strname: "", src: "" },
          { strname: "", src: "" },
          { strname: "", src: "" },
          { strname: "", src: "" },
          { strname: "", src: "" },
        ];

        this.UserFielddata.listImageLookup = [];
        this.UserFielddata.strImageValue = "";
        this.srcImgFixed = "";
        // this.UserFielddata.Lookuppath = [];
        this.UserFielddata.strImageLookupValue = [];
        this.blnclick = false;
        this.listSrcImage = [];
        this.strDatatype = this.stroldDatatype;
        this.stroldDatatype = this.UserFielddata.strDatatype;
        // this.UserFielddata.strDatatype = this.stroldDatatype;
        this.blnChangeDatatype = false;
        this.blnShowLookUpList = true;
        // console.log(this.strDatatype);

        // clear inputText
        this.UserFielddata.strValue = "";
        setTimeout(() => {
          this.resetDataDropdown();
        }, 500);
        // inputDate
        // if (this.strDatatype == 5) {
        //   this.UserFielddata.strValue = undefined;
        // }
        // if (this.strDatatype == 6) {
        //   this.UserFielddata.strValue = undefined;
        // }
      } else if (state == false) {
        this.blnChangeDatatype = false;
        // console.log(this.UserFielddata);
        // console.log(this.stroldDatatype);
        // this.stroldDatatype = this.UserFielddata.strDatatype;
        this.UserFielddata.strDatatype = this.stroldDatatype;

        // fix
        if (this.UserFielddata.intFieldType == 2) {
          this.$refs.dropdownDatatype2.$refs.Dropdown.resetData();
          // console.log(this.UserFielddata.strValue);
        }

        // lookup
        if (this.UserFielddata.intFieldType == 4) {
          this.$refs.dropdownDatatype4.$refs.Dropdown.resetData();
        }
        this.blnChangeDatatype = false;
        this.UserFielddata.strDatatype = this.stroldDatatype;
        setTimeout(() => {
          this.resetDataDropdown();
        }, 500);
      }
    },
    resetDataDropdown() {
      // by end user
      if (this.UserFielddata.intFieldType == 3) {
        this.$refs.dropdownDatatype3.$refs.Dropdown.resetData();
      }
      // fix
      if (this.UserFielddata.intFieldType == 2) {
        this.$refs.dropdownDatatype2.$refs.Dropdown.resetData();
        // console.log(this.UserFielddata.strValue);
      }

      // lookup
      if (this.UserFielddata.intFieldType == 4) {
        this.$refs.dropdownDatatype4.$refs.Dropdown.resetData();
        this.$refs.dropdownComponenttype4.$refs.Dropdown.resetData();
      }
    },
    selectComponentType(state) {
      // console.log(state);
      this.UserFielddata.intDisplayType = state.value;
    },
    selectDataType(state) {
      // console.log(state);
      if (this.blnChangeFieldtype == false) {
        if (this.UserFielddata.intFieldType != 3) {
          if (
            this.UserFielddata.strLookupValue.length > 0 ||
            this.UserFielddata.listImageLookup.length > 0 ||
            this.UserFielddata.strImageValue != "" ||
            (this.UserFielddata.strValue != "" &&
              this.UserFielddata.strValue != undefined)
          ) {
            this.UserFielddata.strDatatype = state.value;
            this.strDatatype = state.value;
            // this.beforDatatype = state.value;
            // console.log(this.UserFielddata.strDatatype);
            // console.log(this.stroldDatatype);
            // setTimeout(() => {
            if (this.UserFielddata.strDatatype != this.stroldDatatype) {
              this.blnChangeDatatype = true;
              this.blnShowLookUpList = true;
            }
            // }, 100);
          } else if (
            this.UserFielddata.intFieldType == 2 &&
            (this.UserFielddata.strValue == "" ||
              this.UserFielddata.strValue == undefined)
          ) {
            this.stroldDatatype = state.value;
          } else if (
            this.UserFielddata.intFieldType == 4 &&
            (this.UserFielddata.listImageLookup.length <= 0 ||
              this.UserFielddata.strImageLookupValue.length <= 0 ||
              this.UserFielddata.strLookupValue.length <= 0)
          ) {
            this.stroldDatatype = state.value;
          } else if (
            this.UserFielddata.listImageLookup.length <= 0 ||
            this.UserFielddata.strImageLookupValue.length <= 0 ||
            this.UserFielddata.strLookupValue.length <= 0
          ) {
            if (this.action == "Insert") {
              if (this.UserFielddata.strImageLookupValue.length <= 0) {
                this.UserFielddata.strImageLookupValue = [];
                this.Listfile = [
                  { strname: "", src: "" },
                  { strname: "", src: "" },
                  { strname: "", src: "" },
                  { strname: "", src: "" },
                  { strname: "", src: "" },
                  { strname: "", src: "" },
                  { strname: "", src: "" },
                  { strname: "", src: "" },
                  { strname: "", src: "" },
                  { strname: "", src: "" },
                  { strname: "", src: "" },
                  { strname: "", src: "" },
                ];
              }
            } else if (this.action == "Update") {
              if (this.UserFielddata.listImageLookup.length <= 0) {
                this.UserFielddata.listImageLookup = [];
                this.Listfile = [
                  { strname: "", src: "" },
                  { strname: "", src: "" },
                  { strname: "", src: "" },
                  { strname: "", src: "" },
                  { strname: "", src: "" },
                  { strname: "", src: "" },
                  { strname: "", src: "" },
                  { strname: "", src: "" },
                  { strname: "", src: "" },
                  { strname: "", src: "" },
                  { strname: "", src: "" },
                  { strname: "", src: "" },
                ];
              }
            }
          }
        } else if (this.UserFielddata.intFieldType == 3) {
          this.UserFielddata.listImageLookup = [];
          this.UserFielddata.strImageLookupValue = [];
          this.UserFielddata.strLookupValue = [];
          this.UserFielddata.strValue = "";
          this.UserFielddata.strImageValue = "";
          this.Listfile = [
            { strname: "", src: "" },
            { strname: "", src: "" },
            { strname: "", src: "" },
            { strname: "", src: "" },
            { strname: "", src: "" },
            { strname: "", src: "" },
            { strname: "", src: "" },
            { strname: "", src: "" },
            { strname: "", src: "" },
            { strname: "", src: "" },
            { strname: "", src: "" },
            { strname: "", src: "" },
          ];
          this.UserFielddata.strDatatype = state.value;
          this.strDatatype = state.value;
        }
        if (this.UserFielddata.strLookupValue.length <= 0) {
          // this.stroldDatatype = state.value;
          this.lookupImg = [];
          this.lookuplist = [];
          this.lookupvalue = {};
          this.UserFielddata.strLookupValue = [];
          // this.UserFielddata.strValue = "";
          this.strDatatype = state.value;
          this.UserFielddata.strDatatype = state.value;
          // this.blnShowLookUpList = true;

          if (this.UserFielddata.strDatatype != 1) {
            this.UserFielddata.intLangcontrolst = 0;
          }
        }
        // setTimeout(() => {
        //   if (this.blnChangeDatatype != true) {
        // clear inputText
        // if (this.strDatatype != 0) {
        // this.UserFielddata.strValue = "";
        // }
        // inputDate
        // if (this.strDatatype == 5) {
        //   this.UserFielddata.strValue = undefined;
        // }
        // if (this.strDatatype == 6) {
        //   this.UserFielddata.strValue = undefined;
        // }
        //   }
        // }, 100);
      }
    },
    confirmselectFieldType(state) {
      // console.log(state);
      if (state == true) {
        this.UserFielddata.strValue = "";
        this.UserFielddata.strImageValue = "";
        this.srcImgFixed = "";
        this.UserFielddata.strLookupValue = [];
        this.UserFielddata.strImageLookupValue = [];
        this.listLengthImage = [];
        this.UserFielddata.strFileID = [];
        if (this.UserFielddata.listImageLookup.length > 0) {
          for (let i = 0; i < this.listImgData.length; i++) {
            const element = this.listImgData[i];
            if (
              element != null &&
              element.fileID != null &&
              element.fileID != ""
            ) {
              this.UserFielddata.strFileID.push(element.fileID);
            }

            this.liststrImgID = [];
            this.liststrImgID = this.UserFielddata.strFileID;
          }
        }
        this.Listfile = [
          { strname: "", src: "" },
          { strname: "", src: "" },
          { strname: "", src: "" },
          { strname: "", src: "" },
          { strname: "", src: "" },
          { strname: "", src: "" },
          { strname: "", src: "" },
          { strname: "", src: "" },
          { strname: "", src: "" },
          { strname: "", src: "" },
          { strname: "", src: "" },
          { strname: "", src: "" },
        ];
        this.UserFielddata.listImageLookup = [];
        this.lookupImg = [];
        this.lookuplist = [];
        this.lookupvalue = {};
        this.stroldFieldtype = this.UserFielddata.intFieldType;
        this.stroldDatatype = this.UserFielddata.strDatatype;
        this.blnChangeFieldtype = false;
        this.blnShowLookUpList = true;
        this.strDatatype = this.UserFielddata.strDatatype;
        this.blnclick = false;
        this.listSrcImage = [];
        setTimeout(() => {
          this.resetDataDropdown();
        }, 500);
      } else if (state == false) {
        this.blnChangeFieldtype = false;
        this.UserFielddata.intFieldType = this.stroldFieldtype;
        this.UserFielddata.strDatatype = this.stroldDatatype;
        this.fieldtype = this.stroldFieldtype;
        this.strDatatype = this.stroldDatatype;
        // console.log(this.stroldFieldtype);
        // console.log(this.stroldDatatype);
        // console.log(this.fieldtype);
        // console.log(this.strDatatype);
        if(this.$refs["radioFieldtype" + this.stroldFieldtype][0].$refs.radio != undefined){
            this.$refs["radioFieldtype" + this.stroldFieldtype][0].$refs.radio.checked = true;
        }

        if (this.UserFielddata.strImageLookupValue >= 2) {
          this.blnclick = true;
        }
        if (this.blnclick == false) {
          this.UserFielddata.strImageLookupValue = [];
        }
      }
    },
    FieldType(state) {
      // console.log(state);
      // 21/03/66 เพิ่มเงื่อนไขตรวจสอบการแสดง popup สำหรับ lookupImg
      // reset ภาษาที่ใช้เป็น 0
      // this.UserFielddata.intLangcontrolst = 0;

      // PopUp chang fieldtype
      if (this.UserFielddata.intFieldType == 2) {
        if (this.UserFielddata.strDatatype != 8) {
          if (
            this.UserFielddata.strValue == "" ||
            this.UserFielddata.strValue == undefined ||
            this.UserFielddata.strValue == null
          ) {
            this.blnChangeFieldtype = false;
            this.stroldFieldtype = state;
          } else {
            this.blnChangeFieldtype = true;
          }
        } else if (this.UserFielddata.strDatatype == 8) {
          if (
            this.UserFielddata.strImageValue == "" ||
            this.UserFielddata.strImageValue == undefined ||
            this.UserFielddata.strImageValue == null
          ) {
            this.blnChangeFieldtype = false;
            this.stroldFieldtype = state;
          } else {
            this.blnChangeFieldtype = true;
          }
        }
      } else if (this.UserFielddata.intFieldType == 4) {
        if (this.UserFielddata.strDatatype != 8) {
          if (this.UserFielddata.strLookupValue.length > 0) {
            this.blnChangeFieldtype = true;
          } else {
            this.blnChangeFieldtype = false;
            this.stroldFieldtype = state;
          }
        } else if (this.UserFielddata.strDatatype == 8) {
          if (this.action == "Insert") {
            if (
              this.UserFielddata.strImageLookupValue.length > 0
              // &&
              // this.blnclick == true
            ) {
              this.blnChangeFieldtype = true;
            } else {
              this.blnChangeFieldtype = false;
              this.stroldFieldtype = state;
            }
          } else if (this.action == "Update") {
            if (
              this.UserFielddata.listImageLookup.length > 0
              // &&
              // this.blnclick == true
            ) {
              this.blnChangeFieldtype = true;
            } else {
              this.blnChangeFieldtype = false;
              this.stroldFieldtype = state;
            }
          }
        }
      } else if (this.UserFielddata.intFieldType == 3) {
        this.stroldFieldtype = state;
        this.UserFielddata.listImageLookup = [];
        this.UserFielddata.strLookupValue = [];
        this.UserFielddata.strValue = "";
        this.UserFielddata.strImageValue = "";
      }

      if (this.loading == true) {
        this.UserFielddata.strValue = this.formdata.strValue;
      }

      // console.log("fieldType : " + state.id);
      // console.log("fieldType : " + state);
      if (state.id == 1 || state == 1) {
        this.UserFielddata.intFieldType = 1;
        this.fieldtype = 1;
      } else if (state.id == 2 || state == 2) {
        this.UserFielddata.intDisplayType = 0;
        this.UserFielddata.intFieldType = 2;
        this.fieldtype = 2;

        this.strDatatype = 1;
        this.UserFielddata.strDatatype = 1;

        this.listDropdrown = [
          {
            id: 1,
            lang: "120", //Text
          },
          {
            id: 2,
            lang: "121", //Number
          },
          {
            id: 3,
            lang: "123", //Currency
          },
          {
            id: 4,
            lang: "139", //Date
          },
          {
            id: 5,
            lang: "140", //Time
          },
          {
            id: 6,
            lang: "146", //Date & Time
          },
          {
            id: 7,
            lang: "70", //Email
          },
          {
            id: 8,
            lang: "203", //Image
          },
          {
            id: 9,
            lang: "69", //Phone
          },
        ];

        if (this.UserFielddata.intFieldType == 2) {
          this.optionList[2].checked = false;
        }
      } else if (state.id == 3 || state == 3) {
        this.UserFielddata.intDisplayType = 0;
        this.UserFielddata.intFieldType = 3;
        this.fieldtype = 3;
        // console.log(this.fieldtype);
        this.UserFielddata.strDatatype = 1;
        if (this.UserFielddata.intIdentifier == 1) {
          this.listDropdrown = [
            {
              id: 1,
              lang: "120", //Text
            },
            {
              id: 2,
              lang: "121", //Number
            },

            {
              id: 7,
              lang: "70", //Email
            },
          ];
        } else if (this.UserFielddata.intIdentifier != 1) {
          this.listDropdrown = [
            {
              id: 1,
              lang: "120", //Text
            },
            {
              id: 2,
              lang: "121", //Number
            },
            {
              id: 3,
              lang: "123", //Currency
            },
            {
              id: 4,
              lang: "139", //Date
            },
            {
              id: 5,
              lang: "140", //Time
            },
            
            {
              id: 6,
              lang: "146", //Date & Time
            },
            {
              id: 7,
              lang: "70", //Email
            },
            {
              id: 8,
              lang: "203", //Image
            },
            {
              id: 9,
              lang: "69", //Phone
            },
          ];
        }
        setTimeout(() => {
          if (this.$refs.radio31 != undefined) {
            this.strDatatype = 1;
            if (this.$refs.radio31[0] != undefined) {
              this.$refs.radio31[0].check = true;
            }
          }
        }, 100);
      } else if (state.id == 4 || state == 4) {
        this.UserFielddata.intDisplayType = 1;
        this.UserFielddata.intFieldType = 4;
        this.UserFielddata.strDatatype = 1;
        this.strDatatype = 1;

        this.radioList = [
          {
            id: 1,
            name: "120", //Text
          },
          {
            id: 2,
            name: "121", //Number
          },
          {
            id: 3,
            name: "123", //Currency
          },
          {
            id: 4,
            name: "140", //Time
          },
          {
            id: 5,
            name: "139", //Date
          },
          {
            id: 6,
            name: "146", //Date & Time
          },
          {
            id: 7,
            name: "70", //Email
          },
        ];
        this.fieldtype = 4;
      }

      // console.log(this.fieldtype);
      // console.log(this.UserFielddata.strDatatype);
      // console.log(this.UserFielddata.strValue);

      setTimeout(() => {
        this.loading = false;
      }, 500);
    },
    selectOption(state, event) {
      //  intRequire: 0,
      // intIdentifier: 0,
      // intRepeatable: 0,
      // intNotnull: 0,
      // intSearchflag: 0,
      // console.log(state);
      // console.log(this.optionList);
      this.optionList[state].checked = event;
      if (state == 0 && event == true) {
        this.UserFielddata.intNotnull = 1;
      } else if (state == 1 && event == true) {
        this.UserFielddata.intRepeatable = 1;
      } else if (state == 2 && event == true) {
        this.UserFielddata.intTemplateDifinest = 1;
        this.$refs.radioFieldtype2[0].$refs.radio.checked = false;
        this.$refs.radioFieldtype2[0].check = false;
        if (this.UserFielddata.intFieldType == 2) {
          this.$refs.radioFieldtype3[0].$refs.radio.checked = true;
          this.fieldtype = 3;
          this.UserFielddata.intFieldType = 3;
          this.UserFielddata.strValue = "";
          this.UserFielddata.strValueEN = "";
        }
      }

      if (state == 0 && event == false) {
        this.UserFielddata.intNotnull = 0;
      } else if (state == 1 && event == false) {
        this.UserFielddata.intRepeatable = 0;
      } else if (state == 2 && event == false) {
        this.UserFielddata.intTemplateDifinest = 0;
      }
      // this.$refs["option2"][0].blnChange = false;
      // if (state == 0 && event == true) {
      //   this.optionList[3].checked = true;
      //   this.UserFielddata.intRequire = 1;
      //   this.UserFielddata.intNotnull = 1;
      // }
      // if (state == 1 && event == true) {
      //   // this.optionList[2].checked = true;
      //   this.optionList[4].checked = true;
      //   this.UserFielddata.intIdentifier = 1;
      //   this.UserFielddata.intSearchflag = 1;
      //   this.UserFielddata.intRepeatable = 0;
      //   this.optionList[2].checked = false;
      //   this.$refs["option2"][0].blnChange = true;
      //   // console.log(this.$refs["option2"][0]);
      // }
      // if (state == 2 && event == true) {
      //   this.UserFielddata.intRepeatable = 1;
      //   this.UserFielddata.intIdentifier = 0;
      //   this.$refs["option1"][0].blnChange = true;
      // }
      // if (state == 3 && event == true) {
      //   this.UserFielddata.intNotnull = 1;
      // }
      // if (state == 4 && event == true) {
      //   this.UserFielddata.intSearchflag = 1;
      // }

      // //false
      // if (state == 0 && event == false) {
      //   this.UserFielddata.intRequire = 0;
      //   this.UserFielddata.intNotnull = 0;
      //   this.optionList[3].checked = false;
      // }
      // if (state == 1 && event == false) {
      //   this.UserFielddata.intIdentifier = 0;
      //   this.UserFielddata.intSearchflag = 0;
      //   this.optionList[4].checked = false;
      //   this.strDatatype = 1;
      //   this.$refs["option2"][0].blnChange = false;
      // }
      // if (state == 2 && event == false) {
      //   this.UserFielddata.intRepeatable = 0;
      //   this.strDatatype = 1;
      //   this.$refs["option1"][0].blnChange = false;
      // }
      // if (state == 3 && event == false) {
      //   if (this.UserFielddata.intRequire == 1) {
      //     this.UserFielddata.intNotnull = 1;
      //     this.optionList[state].checked = true;
      //   } else {
      //     this.UserFielddata.intNotnull = 0;
      //     this.optionList[state].checked = false;
      //   }
      // }
      // if (state == 4 && event == false) {
      //   console.log(this.UserFielddata.intIdentifier);
      //   if (this.UserFielddata.intIdentifier == 1) {
      //     this.UserFielddata.intSearchflag = 1;
      //     this.optionList[state].checked = true;
      //   } else {
      //     this.UserFielddata.intSearchflag = 0;
      //     this.optionList[state].checked = false;
      //   }
      // }
      // if (this.UserFielddata.intIdentifier == 1) {
      //   this.$refs["option2"][0].blnChange = true;

      //   this.listSearch = [
      //     {
      //       id: 3,
      //       lang: "135", // Variable
      //     },
      //   ];
      //   console.log(this.$refs.SelectDropdown);
      //   this.$refs.SelectDropdown.onSeletction(this.listSearch[0]);

      //   //this.fieldType(2);
      // } else if (this.UserFielddata.intRepeatable == 0) {
      //   this.listSearch = [
      //     {
      //       id: 3,
      //       lang: "135", // Variable
      //     },
      //   ];
      //   console.log(this.$refs.SelectDropdown);
      //   this.$refs.SelectDropdown.onSeletction(this.listSearch[0]);
      //   this.strDatatype = 1;

      //   // this.fieldType(3);
      // } else if (
      //   this.UserFielddata.intRepeatable != 0 &&
      //   this.UserFielddata.intIdentifier == 0
      // ) {
      //   this.listSearch = [
      //     {
      //       id: 2,
      //       lang: "138", //Fixed
      //     },
      //     {
      //       id: 3,
      //       lang: "135", // Variable
      //     },
      //     {
      //       id: 4,
      //       lang: "137",
      //       //Lookup
      //     },
      //   ];
      //   // this.fieldType(2);
      //   console.log(this.$refs.SelectDropdown);
      //   this.$refs.SelectDropdown.onSeletction(this.listSearch[0]);
      // }
    },
    checkedRadio(id) {
      // console.log(id.toString() === this.formdata.strDatatype.toString());
      // console.log(this.formdata.strDatatype);
      if (id.toString() === this.formdata.strDatatype.toString()) {
        return true;
      } else {
        return false;
      }
    },
    updateFieldname(state, event) {
      // console.log(state);
      // console.log(event);

      if (state !== "strIcon" && state !== "strImageValue") {
        this.UserFielddata[state] = event.target.value;
        this.UserFielddata.strFieldnameth = this.UserFielddata.strFieldName;
        // console.log(this.UserFielddata[state]);
      } else {
        this.UserFielddata[state] = event;
        if (state == "strIcon") {
          if (event != null) {
            this.UserFielddata.strIconName = event.name;
          }

          if (
            this.UserFielddata.strIcon == "" ||
            this.UserFielddata.strIcon == null
          ) {
            this.UserFielddata.strIconName = "";
          }
        }
        if (state == "strImageValue") {
          // console.log(event);
          var reader = new FileReader();
          let base64;
          reader.readAsDataURL(event);
          reader.onload = () => {
            base64 = reader.result;
          };
          setTimeout(() => {
            // console.log(base64);
            this.srcImgFixed = base64;
          }, 100);
        }
      }
    },
    updateData(state, event, type) {
      // console.log(state);
      // console.log(event);
      // console.log("type ::" + type);
      // console.log(type == 3);

      if (type == 4) {
        this.lookupImg = event;
        // console.log(this.lookupImg);
        // this.imgUrl[state] = URL.createObjectURL(event);
      } else if (type == null) {
        this.lookupvalue[state] =
          event.target == undefined ? event : event.target.value;
      } else {
        this.UserFielddata[state] =
          event.target == undefined ? event : event.target.value;
        // console.log(this.UserFielddata[state]);
      }

      // console.log(this.lookupvalue);
      // console.log(this.UserFielddata);
    },
    // removeLookup(index) {
    //   console.log("index ::" + index);
    //   console.log("this.lookupList");
    //   this.UserFielddata.strLookupValue.splice(index, 1);

    //   console.log(this.lookupList);
    // },
    remove(list, index) {
      // console.log(list);
      // console.log(this.lookupList);
      // console.log(this.strDatatype);
      // console.log(index);

      // if (this.strDatatype == 1) {
      //   this.UserFielddata.strLookupValue =
      //     this.UserFielddata.strLookupValue.filter(function (ele) {
      //       var th = ele.strNameTH.toString() !== list.strNameTH.toString();
      //       var en = ele.strNameEN.toString() !== list.strNameEN.toString();
      //       return th == true || en == true ? true : false;
      //     });
      // } else {
      //   this.UserFielddata.strLookupValue =
      //     this.UserFielddata.strLookupValue.filter(function (ele) {
      //       console.log(ele);
      //       console.log(ele.value !== list.value);
      //       return ele.value !== list.value;
      //     });
      // }
      this.lookuplist = this.UserFielddata.strLookupValue;
      this.lookuplist.splice(index, 1);

      // console.log(this.lookuplist);

      for (let i = 0; i < this.lookuplist.length; i++) {
        if (this.strDatatype == 4) {
          this.$refs["inputTextLookUpListTime" + i][0].updateValue();
        } else if (this.strDatatype == 5) {
          this.$refs["inputTextLookUpListDate" + i][0].UpdateData();
        } else if (this.strDatatype == 6) {
          this.$refs["inputTextLookUpListDateTime" + i][0].UpdateData();
        }
      }
    },
    checkRepeatLookup() {
      if (this.strDatatype == 1) {
        // console.log(this.lookupvalue);
        // console.log(this.UserFielddata.strLookupValue);
        let checkRepeat = false;
        this.UserFielddata.strLookupValue.forEach((element) => {
          if (
            element.strNameEN == this.lookupvalue.strNameEN ||
            element.strNameTH == this.lookupvalue.strNameTH
          ) {
            if (element.strNameEN == this.lookupvalue.strNameEN) {
              // console.log("Repeat EN");
              setTimeout(() => {
                this.$refs.strValueLookupText1.alertMsgStatus = true;
                this.$refs.strValueLookupText1.alertMsgInput = this.$t("904", {
                  fieldname: this.$t("445"),
                });
              }, 100);
            }
            if (element.strNameTH == this.lookupvalue.strNameTH) {
              // console.log("Repeat TH");
              setTimeout(() => {
                this.$refs.strValueLookupText2.alertMsgStatus = true;
                this.$refs.strValueLookupText2.alertMsgInput = this.$t("904", {
                  fieldname: this.$t("444"),
                });
              }, 100);
            }
            checkRepeat = true;
            return true;
          } else {
            return false;
          }
        });
        return checkRepeat;
      } else if (this.strDatatype != 1 && this.strDatatype != 8) {
        let checkRepeat = false;
        var refname = "strDatatype" + this.strDatatype.toString();
        let MSG =
          this.strDatatype == 2
            ? "121"
            : this.strDatatype == 3
            ? "123"
            : this.strDatatype == 4
            ? "140"
            : this.strDatatype == 5
            ? "139"
            : this.strDatatype == 6
            ? "146"
            : "70";
        this.UserFielddata.strLookupValue.forEach((element) => {
          if (element.value == this.lookupvalue.value) {
            // console.log("Repeat");
            setTimeout(() => {
              this.$refs[refname].alertMsgStatus = true;
              this.$refs[refname].alertMsgInput = this.$t("904", {
                fieldname: this.$t(MSG),
              });
            }, 100);
            checkRepeat = true;
          }
        });
        return checkRepeat;
      }
    },
    addLookupText() {
      // console.log("addLookupText()");
      // console.log(this.lookupvalue);
      // console.log(this.lookupImg);

      if (this.strDatatype == 1) {
        if (
          this.$refs.strValueLookupText1 != undefined &&
          this.$refs.strValueLookupText2 != undefined
        ) {
          // this.$refs.strValueLookupText1.alertRequest();
          this.$refs.strValueLookupText1.Valid();
          // this.$refs.strValueLookupText1.alertMsgStatus;

          // this.$refs.strValueLookupText2.alertRequest();
          this.$refs.strValueLookupText2.Valid();
          // this.$refs.strValueLookupText2.alertMsgStatus;

          if (
            this.$refs.strValueLookupText1.alertMsgStatus == false &&
            this.$refs.strValueLookupText2.alertMsgStatus == false
          ) {
            let isEqual = this.checkRepeatLookup();
            if (isEqual != true) {
              const text = { ...this.lookupvalue };
              // this.lookuplist.push({ ...text });
              this.insertObject(this.UserFielddata.strLookupValue, { ...text });
              // console.log(text);
              this.lookupvalue = {};
              this.alertMsgStatus = false;
            }
          }
        }
      } else {
        var refname = "strDatatype" + this.strDatatype.toString();
        if (this.isEmpty(this.lookupvalue) == false) {
          this.$refs[refname].Valid();
          if (this.$refs[refname].alertMsgStatus == false) {
            let isEqual = this.checkRepeatLookup();
            if (isEqual != true) {
              const text = { ...this.lookupvalue };
              // this.lookuplist.push({ ...text });
              this.insertObject(this.UserFielddata.strLookupValue, { ...text });
              this.alertMsgStatus = false;
              // console.log(text);
              this.lookupvalue = {};
            }
          }
        }
      }
    },

    insertObject(mainList, newList) {
      // console.log("insertObject");
      mainList.push(newList);
      // console.log(mainList);
    },
    isEmpty(value) {
      return (
        // null or undefined
        value == null ||
        // has length and it's zero
        value.length === 0 ||
        // is an Object and has no keys
        (value.constructor === Object && Object.keys(value).length === 0)
      );
    },

    addData() {
      // console.log(this.UserFielddata);
      if (
        this.UserFielddata.strIcon == "" ||
        this.UserFielddata.strIcon == null
      ) {
        this.UserFielddata.strIconName = "";
      }
      if (this.UserFielddata.intFieldType == 2) {
        if (this.UserFielddata.strDatatype != 8) {
          this.UserFielddata.strImageValue = "";
          this.UserFielddata.strOldImageValue = "";
          this.UserFielddata.strLookupValue = [];
          this.UserFielddata.strImageLookupValue = [];
          this.UserFielddata.listImageLookup = [];
        } else if (this.UserFielddata.strDatatype == 8) {
          this.UserFielddata.strValue = "";
          this.UserFielddata.strLookupValue = [];
          if (this.action == "Insert") {
            this.UserFielddata.listImageLookup = [];
          } else if (this.action == "Update") {
            this.UserFielddata.strImageLookupValue = [];
          }
          // console.log(this.UserFielddata);
        }
      } else if (this.UserFielddata.intFieldType == 3) {
        this.UserFielddata.strValue = "";
        this.UserFielddata.strImageValue = "";
        this.UserFielddata.strOldImageValue = "";
        this.UserFielddata.strLookupValue = [];
        this.UserFielddata.strImageLookupValue = [];
        this.UserFielddata.listImageLookup = [];
      } else if (
        this.UserFielddata.intFieldType == 4 &&
        this.UserFielddata.strDatatype != 8
      ) {
        this.UserFielddata.strValue = "";
        this.UserFielddata.strImageValue = "";
        this.UserFielddata.strOldImageValue = "";
        this.UserFielddata.strImageLookupValue = [];
        this.UserFielddata.listImageLookup = [];
      }
      if (
        this.UserFielddata.intFieldType == 4 &&
        this.UserFielddata.strDatatype == 8
      ) {
        if (this.action == "Update") {
          this.UserFielddata.strImageLookupValue = [];
          this.UserFielddata.listImageLookup =
            this.UserFielddata.listImageLookup.filter((ele) => {
              return ele != null;
            });
        } else if (this.action == "Insert") {
          this.UserFielddata.listImageLookup = [];
        }
        // console.log(this.action);
        // console.log(this.UserFielddata);
      }

      var detect = this.detectDatainField();
      // console.log(detect);
      if (detect == true) {
        if (this.action == "Insert") {
          // console.log("Insert");
          this.$emit("Insert", this.UserFielddata);
        } else if (this.action == "Update") {
          // console.log(typeof this.UserFielddata.strImageValue);
          if (
            typeof this.UserFielddata.strImageValue == "string" &&
            this.UserFielddata.strDatatype.toString() == "8"
          ) {
            this.UserFielddata.strValue =
              this.UserFielddata.strOldImageValue.replaceAll("undefined");
          }
          // console.log(this.UserFielddata);
          this.$emit("Update", {
            formdata: this.UserFielddata,
            //campusID: this.new_data["intID"],
          });
        }
      } else console.log("RequestAlert");
      // console.log(detect);
    },
    detectCheckbok(index) {
      // if (this.action == "Update") {
      //   if (this.inactive == false) {
      //     if (index == 0) {
      //       return false;
      //     } else if (index == 1) {
      //       return false;
      //     } else if (index == 2) {
      //       return false;
      //     } else if (index == 3) {
      //       if (this.UserFielddata.intRequire == 1) {
      //         return true;
      //       } else {
      //         return false;
      //       }
      //     } else if (index == 4) {
      //       if (this.UserFielddata.intIdentifier == 1) {
      //         return true;
      //       } else {
      //         return false;
      //       }
      //     }
      //   } else {
      //     return this.inactive;
      //   }
      // } else if (this.action == "Insert") {
      //   if (index == 0) {
      //     return false;
      //   } else if (index == 1) {
      //     return false;
      //   } else if (index == 2) {
      //     return false;
      //   } else if (index == 3) {
      //     if (this.UserFielddata.intRequire == 1) {
      //       return true;
      //     } else {
      //       return false;
      //     }
      //   } else if (index == 4) {
      //     if (this.UserFielddata.intIdentifier == 1) {
      //       return true;
      //     } else {
      //       return false;
      //     }
      //   }
      // }
      // console.log(index);
      // console.log(this.iscandelete);
      if (this.iscandelete == true) {
        return true;
      } else {
        if (this.UserFielddata.intFieldType == 2) {
          if (index == 2) {
            return true;
          } else {
            return false;
          }
        }
      }
    },
    detectDatainField() {
      // console.log(this.UserFielddata);
      // console.log(this.iscandelete);
      if (this.UserFielddata.intFieldType == 3) {
        if (this.isEmpty(this.UserFielddata.strFieldName) == true) {
          // console.log(this.$refs.strFieldName);
          this.$refs.strFieldName.alertRequest();
        }
        if (this.isEmpty(this.UserFielddata.strFieldnameth) == true) {
          this.$refs.strFieldnameth.alertRequest();
        }
        // console.log("false");
        if (
          this.isEmpty(this.UserFielddata.strFieldName) == true ||
          this.isEmpty(this.UserFielddata.strFieldnameth) == true
        ) {
          return false;
        } else if (
          this.isEmpty(this.UserFielddata.strFieldName) == false &&
          this.isEmpty(this.UserFielddata.strFieldnameth) == false
        ) {
          return true;
        }
      }
      if (this.UserFielddata.intFieldType == 2) {
        // console.log(this.UserFielddata);
        //alert
        if (this.isEmpty(this.UserFielddata.strFieldName) == true) {
          // console.log(this.$refs.strFieldName);
          this.$refs.strFieldName.alertRequest();
        }
        if (this.isEmpty(this.UserFielddata.strFieldnameth) == true) {
          this.$refs.strFieldnameth.alertRequest();
        }
        if (this.iscandelete == false) {
          if (this.isEmpty(this.UserFielddata.strValue) == true) {
            if (this.strDatatype != 8) {
              var refname = "strDatatype" + this.strDatatype.toString();
              if (this.$refs[refname] != undefined) {
                this.$refs[refname].alertRequest();
              } else if (this.$refs.strValueNumber != undefined) {
                this.$refs.strValueNumber.alertRequest();
              }
            }
          }
          if (this.strDatatype == 8) {
            if (this.UserFielddata.strImageValue == null) {
              this.$refs.strDatatype8.alertRequest();
            }
            if (this.UserFielddata.strImageValue != null) {
              if (this.UserFielddata.strImageValue == "") {
                this.$refs.strDatatype8.alertRequest();
              }
            }
          }
        } else {
          if (
            this.isEmpty(this.UserFielddata.strValue) == true &&
            this.isEmpty(this.formdata.strValue) == true
          ) {
            if (this.strDatatype != 8) {
              var refnames = "strDatatype" + this.strDatatype.toString();
              if (this.$refs[refnames] != undefined) {
                this.$refs[refnames].alertRequest();
              } else if (this.$refs.strValueNumber != undefined) {
                this.$refs.strValueNumber.alertRequest();
              }
            }
          }
          if (this.strDatatype == 8) {
            if (this.UserFielddata.strImageValue == null) {
              this.$refs.strDatatype8.alertRequest();
            }
            if (this.UserFielddata.strImageValue != null) {
              if (this.UserFielddata.strImageValue == "") {
                this.$refs.strDatatype8.alertRequest();
              }
            }
          }
        }

        //return
        if (this.strDatatype != 8) {
          if (this.iscandelete == false) {
            if (
              this.isEmpty(this.UserFielddata.strFieldName) == true ||
              this.isEmpty(this.UserFielddata.strFieldnameth) == true ||
              (this.isEmpty(this.UserFielddata.strValue) == true &&
                this.isEmpty(this.formdata.strValue) == true)
            ) {
              return false;
            } else if (
              this.isEmpty(this.UserFielddata.strFieldName) == false &&
              this.isEmpty(this.UserFielddata.strFieldnameth) == false &&
              this.isEmpty(this.UserFielddata.strValue) == false
            ) {
              return true;
            }
          } else {
            if (
              this.isEmpty(this.UserFielddata.strFieldName) == true ||
              this.isEmpty(this.UserFielddata.strFieldnameth) == true ||
              (this.isEmpty(this.UserFielddata.strValue) == true &&
                this.isEmpty(this.formdata.strValue) == true)
            ) {
              return false;
            } else if (
              this.isEmpty(this.UserFielddata.strFieldName) == false &&
              this.isEmpty(this.UserFielddata.strFieldnameth) == false &&
              (this.isEmpty(this.UserFielddata.strValue) == false ||
                this.isEmpty(this.formdata.strValue) == false)
            ) {
              return true;
            }
          }
        }
        if (this.strDatatype == 8) {
          if (this.UserFielddata.strImageValue != null) {
            if (
              this.isEmpty(this.UserFielddata.strFieldName) == true ||
              this.isEmpty(this.UserFielddata.strFieldnameth) == true ||
              this.UserFielddata.strImageValue == ""
            ) {
              return false;
            } else if (
              this.isEmpty(this.UserFielddata.strFieldName) == false &&
              this.isEmpty(this.UserFielddata.strFieldnameth) == false &&
              this.UserFielddata.strImageValue != ""
            ) {
              return true;
            }
          } else if (this.UserFielddata.strImageValue == null) {
            return false;
          }
        }
      }
      if (this.UserFielddata.intFieldType == 4) {
        //alert
        if (this.isEmpty(this.UserFielddata.strFieldName) == true) {
          // console.log(this.$refs.strFieldName);
          this.$refs.strFieldName.alertRequest();
        }
        if (this.isEmpty(this.UserFielddata.strFieldnameth) == true) {
          this.$refs.strFieldnameth.alertRequest();
        }
        if (this.UserFielddata.strDatatype != 8) {
          if (this.UserFielddata.strLookupValue.length <= 0) {
            this.alertMsgStatus = true;
          }
        } else if (this.UserFielddata.strDatatype == 8) {
          if (this.action == "Insert") {
            if (this.UserFielddata.strImageLookupValue.length <= 0) {
              this.alertMsgStatus = true;
            }
          } else if (this.action == "Update") {
            if (this.UserFielddata.listImageLookup.length <= 0) {
              this.alertMsgStatus = true;
            }
            // if (this.UserFielddata.Lookuppath.length <= 0) {
            //   this.alertMsgStatus = true;
            // }
          }
        }

        //return
        if (this.UserFielddata.strDatatype != 8) {
          if (
            this.isEmpty(this.UserFielddata.strFieldName) == true ||
            this.isEmpty(this.UserFielddata.strFieldnameth) == true ||
            this.UserFielddata.strLookupValue.length <= 0
          ) {
            return false;
          } else if (
            this.isEmpty(this.UserFielddata.strFieldName) == false &&
            this.isEmpty(this.UserFielddata.strFieldnameth) == false &&
            this.UserFielddata.strLookupValue.length > 0
          ) {
            return true;
          }
        } else if (this.UserFielddata.strDatatype == 8) {
          if (this.action == "Insert") {
            if (
              this.isEmpty(this.UserFielddata.strFieldName) == true ||
              this.isEmpty(this.UserFielddata.strFieldnameth) == true ||
              this.UserFielddata.strImageLookupValue.length <= 0
            ) {
              return false;
            } else if (
              this.isEmpty(this.UserFielddata.strFieldName) == false &&
              this.isEmpty(this.UserFielddata.strFieldnameth) == false &&
              this.UserFielddata.strImageLookupValue.length > 0
            ) {
              return true;
            }
          } else if (this.action == "Update") {
            if (
              this.isEmpty(this.UserFielddata.strFieldName) == true ||
              this.isEmpty(this.UserFielddata.strFieldnameth) == true ||
              this.UserFielddata.listImageLookup.length <= 0
              // this.UserFielddata.Lookuppath.length <= 0
            ) {
              return false;
            } else if (
              this.isEmpty(this.UserFielddata.strFieldName) == false &&
              this.isEmpty(this.UserFielddata.strFieldnameth) == false &&
              this.UserFielddata.listImageLookup.length > 0
              // this.UserFielddata.Lookuppath.length > 0
            ) {
              return true;
            }
          }
        }
      }
    },
    closeForm() {
      if (this.action == "Insert") {
        if (
          this.UserFielddata.strFieldName == "" &&
          this.UserFielddata.intFieldType == 3 &&
          this.UserFielddata.strIconName == "" &&
          this.UserFielddata.strDatatype == 1 &&
          this.UserFielddata.strValue == "" &&
          this.UserFielddata.strImageValue == "" &&
          this.UserFielddata.strOldImageValue == "" &&
          this.UserFielddata.intRequire == 0 &&
          this.UserFielddata.intIdentifier == 0 &&
          this.UserFielddata.intRepeatable == 0 &&
          this.UserFielddata.intNotnull == 0 &&
          this.UserFielddata.strFieldnameth == "" &&
          this.UserFielddata.intSearchflag == 0 &&
          this.UserFielddata.strLookupValue == "" &&
          this.UserFielddata.strImageLookupValue == "" &&
          this.UserFielddata.listImageLookup == "" &&
          this.UserFielddata.intLangcontrolst == 0 &&
          this.UserFielddata.intTemplateDifinest == 0 &&
          this.UserFielddata.strIcon == "" &&
          this.UserFielddata.strFileID == "" &&
          this.UserFielddata.strLabelTH == "" &&
          this.UserFielddata.strLabelEN == "" &&
          this.UserFielddata.strLabelEN == 0
        ) {
          this.blnCloseForm = false;
          this.confirmCloseForm(true);
        } else {
          this.blnCloseForm = true;
        }
      } else if (this.action == "Update") {
        let formdatalookup = "";
        let Userfieldatalookup = "";
        let strImageLookupValue = [];

        if (this.formdata.strLookupValue == "") {
          formdatalookup = this.formdata.strLookupValue;
          Userfieldatalookup = this.UserFielddata.strLookupValue;
        } else if (this.formdata.strLookupValue != "") {
          formdatalookup = JSON.stringify(
            JSON.parse(this.formdata.strLookupValue)
          );
          Userfieldatalookup = JSON.stringify(
            this.UserFielddata.strLookupValue
          );
        }

        if (this.formdata.strImageLookupValue == undefined) {
          strImageLookupValue = undefined;
        } else {
          strImageLookupValue = this.UserFielddata.strImageLookupValue;
        }
        if (
          this.formdata.strFieldName == this.UserFielddata.strFieldName &&
          this.formdata.intFieldType == this.UserFielddata.intFieldType &&
          this.formdata.strIconName == this.UserFielddata.strIconName &&
          this.formdata.strDatatype == this.UserFielddata.strDatatype &&
          this.formdata.strValue == this.UserFielddata.strValue &&
          this.formdata.strImageValue == this.UserFielddata.strImageValue &&
          this.formdata.intRequire == this.UserFielddata.intRequire &&
          this.formdata.intIdentifier == this.UserFielddata.intIdentifier &&
          this.formdata.intRepeatable == this.UserFielddata.intRepeatable &&
          this.formdata.intNotnull == this.UserFielddata.intNotnull &&
          this.formdata.strFieldnameth == this.UserFielddata.strFieldnameth &&
          this.formdata.intSearchflag == this.UserFielddata.intSearchflag &&
          formdatalookup == Userfieldatalookup &&
          this.formdata.strImageLookupValue == strImageLookupValue &&
          JSON.stringify(this.formdata.listImageLookup) ==
            JSON.stringify(this.UserFielddata.listImageLookup) &&
          this.formdata.intLangcontrolst ==
            this.UserFielddata.intLangcontrolst &&
          this.formdata.intTemplateDifinest ==
            this.UserFielddata.intTemplateDifinest &&
          this.formdata.strIcon == this.UserFielddata.strIcon &&
          this.formdata.strFileID == this.UserFielddata.strFileID &&
          this.formdata.strLabelTH == this.UserFielddata.strLabelTH &&
          this.formdata.strLabelEN == this.UserFielddata.strLabelEN &&
          this.formdata.intDisplayType == this.UserFielddata.intDisplayType
        ) {
          this.blnCloseForm = false;
          this.confirmCloseForm(true);
        } else {
          this.blnCloseForm = true;
        }
      }
    },
    confirmCloseForm(state) {
      if (state == false) {
        this.blnCloseForm = false;
      } else if (state == true) {
        this.blnCloseForm = false;
        this.$emit("onInput", false);
      }
    },
    deleteData() {
      this.$emit("delete");
    },

    detectalertDataDuplicate(msgalertDataDuplicate) {
      if (this.action == "Insert") {
        if (
          this.UserFielddata.strFieldName == "" &&
          this.UserFielddata.intFieldType == 3 &&
          this.UserFielddata.strIconName == "" &&
          this.UserFielddata.strDatatype == 1 &&
          this.UserFielddata.strValue == "" &&
          this.UserFielddata.strImageValue == "" &&
          this.UserFielddata.strOldImageValue == "" &&
          this.UserFielddata.intRequire == 0 &&
          this.UserFielddata.intIdentifier == 0 &&
          this.UserFielddata.intRepeatable == 0 &&
          this.UserFielddata.intNotnull == 0 &&
          this.UserFielddata.strFieldnameth == "" &&
          this.UserFielddata.intSearchflag == 0 &&
          this.UserFielddata.strLookupValue == "" &&
          this.UserFielddata.strImageLookupValue == "" &&
          this.UserFielddata.listImageLookup == "" &&
          this.UserFielddata.intLangcontrolst == 0 &&
          this.UserFielddata.intTemplateDifinest == 0 &&
          this.UserFielddata.strIcon == "" &&
          this.UserFielddata.strFileID == "" &&
          this.UserFielddata.strLabelTH == "" &&
          this.UserFielddata.strLabelEN == "" &&
          this.UserFielddata.strLabelEN == 0
        ) {
          this.blnCloseForm = false;
          this.confirmCloseForm(true);
        } else if (Array.isArray(msgalertDataDuplicate)) {
          for (var j = 0; j < msgalertDataDuplicate.length; j++) {
            if (msgalertDataDuplicate[j].name === "strFieldName") {
              this.$refs[msgalertDataDuplicate[j].name].alertDataDuplicate(
                msgalertDataDuplicate[j].msg
              );

              break;
            } else {
              // this.blnAdddata = true;
              this.confirmaddData(true)
            }
          }
        } else {
          this.addData();
        }
      } else if (this.action == "Update") {
        let formdatalookup = "";
        let Userfieldatalookup = "";
        let strImageLookupValue = [];
        if (this.formdata.strLookupValue == "") {
          formdatalookup = this.formdata.strLookupValue;
          Userfieldatalookup = this.UserFielddata.strLookupValue;
        } else if (this.formdata.strLookupValue != "") {
          formdatalookup = JSON.stringify(
            JSON.parse(this.formdata.strLookupValue)
          );
          Userfieldatalookup = JSON.stringify(
            this.UserFielddata.strLookupValue
          );
        }

        if (this.formdata.strImageLookupValue == undefined) {
          strImageLookupValue = undefined;
        } else {
          strImageLookupValue = this.UserFielddata.strImageLookupValue;
        }
        if (
          this.formdata.strFieldName == this.UserFielddata.strFieldName &&
          this.formdata.intFieldType == this.UserFielddata.intFieldType &&
          this.formdata.strIconName == this.UserFielddata.strIconName &&
          this.formdata.strDatatype == this.UserFielddata.strDatatype &&
          this.formdata.strValue == this.UserFielddata.strValue &&
          this.formdata.strImageValue == this.UserFielddata.strImageValue &&
          this.formdata.intRequire == this.UserFielddata.intRequire &&
          this.formdata.intIdentifier == this.UserFielddata.intIdentifier &&
          this.formdata.intRepeatable == this.UserFielddata.intRepeatable &&
          this.formdata.intNotnull == this.UserFielddata.intNotnull &&
          this.formdata.strFieldnameth == this.UserFielddata.strFieldnameth &&
          this.formdata.intSearchflag == this.UserFielddata.intSearchflag &&
          formdatalookup == Userfieldatalookup &&
          this.formdata.strImageLookupValue == strImageLookupValue &&
          JSON.stringify(this.formdata.listImageLookup) ==
            JSON.stringify(this.UserFielddata.listImageLookup) &&
          this.formdata.intLangcontrolst ==
            this.UserFielddata.intLangcontrolst &&
          this.formdata.intTemplateDifinest ==
            this.UserFielddata.intTemplateDifinest &&
          this.formdata.strIcon == this.UserFielddata.strIcon &&
          this.formdata.strFileID == this.UserFielddata.strFileID &&
          this.formdata.strLabelTH == this.UserFielddata.strLabelTH &&
          this.formdata.strLabelEN == this.UserFielddata.strLabelEN &&
          this.formdata.intDisplayType == this.UserFielddata.intDisplayType
        ) {
          this.confirmCloseForm(true);
        } else if (Array.isArray(msgalertDataDuplicate)) {
          for (var i = 0; i < msgalertDataDuplicate.length; i++) {
            if (msgalertDataDuplicate[i].name === "strFieldName") {
              this.$refs[msgalertDataDuplicate[i].name].alertDataDuplicate(
                msgalertDataDuplicate[i].msg
              );

              break;
            } else {
              // this.blnAdddata = true;
              this.confirmaddData(true)
            }
          }
        } else {
          this.addData();
        }
      }
    },
    checkDataNotnull() {
      if (
        this.UserFielddata.intFieldType == null ||
        this.UserFielddata.intFieldType == undefined ||
        this.UserFielddata.intFieldType == "" ||
        this.UserFielddata.strDatatype == null ||
        this.UserFielddata.strDatatype == undefined ||
        this.UserFielddata.strDatatype == "" ||
        this.UserFielddata.strFieldName == null ||
        this.UserFielddata.strFieldName == undefined ||
        this.UserFielddata.strFieldName == "" ||
        this.UserFielddata.strLabelTH == null ||
        this.UserFielddata.strLabelTH == undefined ||
        this.UserFielddata.strLabelTH == "" ||
        this.UserFielddata.strLabelEN == null ||
        this.UserFielddata.strLabelEN == undefined ||
        this.UserFielddata.strLabelEN == ""
      ) {
        return true;
      }
      if (this.UserFielddata.intFieldType == 2) {
        if (this.UserFielddata.strDatatype != 8) {
          if (
            this.UserFielddata.strValue == null ||
            this.UserFielddata.strValue == undefined ||
            this.UserFielddata.strValue == ""
          ) {
            return true;
          } else {
            return false;
          }
        } else if (this.UserFielddata.strDatatype == 8) {
          if (
            this.UserFielddata.strImageValue == null ||
            this.UserFielddata.strImageValue == undefined ||
            this.UserFielddata.strImageValue == ""
          ) {
            return true;
          } else {
            return false;
          }
        }
      } else if (this.UserFielddata.intFieldType == 4) {
        if (this.UserFielddata.strDatatype == 8) {
          if (this.action == "Insert") {
            if (
              this.UserFielddata.strImageLookupValue.length < 2 ||
              this.listSrcImage.length < 2 ||
              this.blnShowLookUpList == true ||
              this.blnclick == false
            ) {
              return true;
            } else {
              return false;
            }
          } else if (this.action == "Update") {
            if (
              this.UserFielddata.listImageLookup.length < 2 ||
              // this.UserFielddata.Lookuppath.length < 2 ||
              this.listSrcImage.length < 2 ||
              this.blnShowLookUpList == true ||
              this.blnclick == false
            ) {
              return true;
            } else {
              return false;
            }
          }
        } else if (this.UserFielddata.strDatatype != 8) {
          if (
            this.UserFielddata.strLookupValue.length < 2 ||
            this.blnShowLookUpList == true
          ) {
            return true;
          } else {
            return false;
          }
        }
      }
    },
    checkDataLookUp() {
      if (this.UserFielddata.intFieldType == 4) {
        if (this.UserFielddata.strLookupValue.length < 2) {
          return true;
        } else {
          return false;
        }
      }
    },
    CheckDataAddLookUp() {
      if (this.UserFielddata.intFieldType == 4) {
        if (this.UserFielddata.strDatatype == 1) {
          if (
            this.lookupvalue.strNameTH == undefined ||
            this.lookupvalue.strNameTH == "" ||
            this.lookupvalue.strNameEN == undefined ||
            this.lookupvalue.strNameEN == "" ||
            this.blnShowLookUpList == false
          ) {
            return true;
          } else if (
            this.lookupvalue.strNameTH != undefined &&
            this.lookupvalue.strNameTH != "" &&
            this.lookupvalue.strNameEN != undefined &&
            this.lookupvalue.strNameEN != ""
          ) {
            return false;
          }
        } else {
          if (
            this.lookupvalue.value == undefined ||
            this.lookupvalue.value == "" ||
            this.lookupvalue.value == undefined ||
            this.lookupvalue.value == "" ||
            this.blnShowLookUpList == false
          ) {
            return true;
          } else if (
            this.lookupvalue.value != undefined &&
            this.lookupvalue.value != "" &&
            this.lookupvalue.value != undefined &&
            this.lookupvalue.value != ""
          ) {
            return false;
          }
        }
      }
    },
    UpdateLookUpList(event, index, strInpLang) {
      if (this.UserFielddata.strDatatype == 1) {
        if (strInpLang == "th") {
          this.UserFielddata.strLookupValue[index].strNameTH =
            event.target.value;
        }
        if (strInpLang == "en") {
          this.UserFielddata.strLookupValue[index].strNameEN =
            event.target.value;
        }
      } else if (
        this.UserFielddata.strDatatype == 4 ||
        this.UserFielddata.strDatatype == 5 ||
        this.UserFielddata.strDatatype == 6
      ) {
        this.UserFielddata.strLookupValue[index].value = event;
        // console.log(this.UserFielddata.strLookupValue[index].value);
      } else {
        this.UserFielddata.strLookupValue[index].value = event.target.value;
      }
    },
    checkDuplicateLookUp(element, i) {
      if (this.UserFielddata.strDatatype == 1) {
        // nameTH
        const NameTHDuplicate = this.UserFielddata.strLookupValue.filter(
          (e) => e.strNameTH == element.strNameTH
        );
        if (NameTHDuplicate.length > 1) {
          this.blnShowLookUpList = true;
          setTimeout(() => {
            this.$refs["inputTextLookUpListTH" + i][0].alertMsgStatus = true;
            this.$refs["inputTextLookUpListTH" + i][0].alertMsgInput = this.$t(
              "904",
              {
                fieldname: this.$t("444"),
              }
            );
          }, 100);
        }
        // nameEN
        const NameENDuplicate = this.UserFielddata.strLookupValue.filter(
          (e) => e.strNameEN == element.strNameEN
        );
        if (NameENDuplicate.length > 1) {
          this.blnShowLookUpList = true;
          setTimeout(() => {
            this.$refs["inputTextLookUpListEN" + i][0].alertMsgStatus = true;
            this.$refs["inputTextLookUpListEN" + i][0].alertMsgInput = this.$t(
              "904",
              {
                fieldname: this.$t("445"),
              }
            );
          }, 100);
        }
      } else {
        let refname =
          this.strDatatype == 2
            ? "inputTextLookUpListNumber"
            : this.strDatatype == 3
            ? "inputTextLookUpListCurrency"
            : this.strDatatype == 4
            ? "inputTextLookUpListTime"
            : this.strDatatype == 5
            ? "inputTextLookUpListDate"
            : this.strDatatype == 6
            ? "inputTextLookUpListDateTime"
            : "inputTextLookUpListEmail";
        let MSG =
          this.strDatatype == 2
            ? "121"
            : this.strDatatype == 3
            ? "123"
            : this.strDatatype == 4
            ? "140"
            : this.strDatatype == 5
            ? "139"
            : this.strDatatype == 6
            ? "146"
            : "70";
        const NumberDuplicate = this.UserFielddata.strLookupValue.filter(
          (e) => e.value == element.value
        );
        if (NumberDuplicate.length > 1) {
          this.blnShowLookUpList = true;
          setTimeout(() => {
            this.$refs[refname + i][0].alertMsgStatus = true;
            this.$refs[refname + i][0].alertMsgInput = this.$t("904", {
              fieldname: this.$t(MSG),
            });
          }, 100);
        }
      }
    },
    AddLookUpList() {
      this.blnShowLookUpList = false;
      // check LookUp != null
      for (let i = 0; i < this.UserFielddata.strLookupValue.length; i++) {
        const element = this.UserFielddata.strLookupValue[i];

        // checkDuplicate
        this.checkDuplicateLookUp(element, i);

        if (this.UserFielddata.strDatatype == 1) {
          if (element.strNameTH == "" || element.strNameEN == "") {
            if (element.strNameTH == "") {
              // alert
              this.$refs["inputTextLookUpListTH" + i][0].alertRequest();
            }
            if (element.strNameEN == "") {
              // alert
              this.$refs["inputTextLookUpListEN" + i][0].alertRequest();
            }
            // return;
          }
          if (
            this.$refs["inputTextLookUpListTH" + i][0].alertMsgStatus == true ||
            this.$refs["inputTextLookUpListEN" + i][0].alertMsgStatus == true
          ) {
            this.blnShowLookUpList = true;
          }
        } else if (this.UserFielddata.strDatatype == 2) {
          if (element.value == "") {
            this.$refs["inputTextLookUpListNumber" + i][0].alertRequest();
          }
          if (
            this.$refs["inputTextLookUpListNumber" + i][0].alertMsgStatus ==
            true
          ) {
            this.blnShowLookUpList = true;
          }
        } else if (this.UserFielddata.strDatatype == 3) {
          if (element.value == "") {
            this.$refs["inputTextLookUpListCurrency" + i][0].alertRequest();
          }
          if (
            this.$refs["inputTextLookUpListCurrency" + i][0].alertMsgStatus ==
            true
          ) {
            this.blnShowLookUpList = true;
          }
        } else if (this.UserFielddata.strDatatype == 7) {
          if (element.value == "") {
            this.$refs["inputTextLookUpListEmail" + i][0].alertRequest();
          }
          if (
            this.$refs["inputTextLookUpListEmail" + i][0].alertMsgStatus == true
          ) {
            this.blnShowLookUpList = true;
          }
        } else if (this.UserFielddata.strDatatype == 9) {
          if (element.value == "") {
            this.$refs["inputTextLookUpListPassword" + i][0].alertRequest();
          }
          if (
            this.$refs["inputTextLookUpListPassword" + i][0].alertMsgStatus ==
            true
          ) {
            this.blnShowLookUpList = true;
          }
        }
      }
      if (this.blnShowLookUpList == false) {
        this.$refs.dropdownDatatype4.inactive = true;
        this.$refs.dropdownComponenttype4.inactive = true;
      } else {
        this.$refs.dropdownDatatype4.inactive = false;
        this.$refs.dropdownComponenttype4.inactive = false;
      }
    },
    convertNumberToDate(event) {
      let data = event.split("/");
      if (this.UserFielddata.strDatatype == 5) {
        return (
          data[0] +
          " " +
          (this.$i18n.locale == "th"
            ? this.month_names_th[parseInt(data[1])]
            : this.month_names_en[parseInt(data[1])]) +
          " " +
          (this.$i18n.locale == "th" ? data[2] : parseInt(data[2]) - 543)
        );
      } else if (this.UserFielddata.strDatatype == 6) {
        return (
          data[0] +
          " " +
          (this.$i18n.locale == "th"
            ? this.month_names_th[parseInt(data[1])]
            : this.month_names_en[parseInt(data[1])]) +
          " " +
          (this.$i18n.locale == "th" ? data[2] : parseInt(data[2]) - 543) +
          " " +
          data[3]
        );
      }
    },
    checkDataImageLookup() {
      if (this.UserFielddata.intFieldType == 4) {
        if (this.UserFielddata.strDatatype == 8) {
          // console.log(this.intNotemptylength);
          // console.log(this.listDummyImgLookup);
          if (this.action == "Insert") {
            if (this.listDummyImgLookup.length < 2) {
              return true;
            } else {
              return false;
            }
          } else if (this.action == "Update") {
            if (
              this.intNotemptylength < 2 ||
              this.listDummyImgLookup.length < 2
            ) {
              return true;
            } else {
              return false;
            }
          }
        }
      }
    },
    LangControl(state) {
      if (this.UserFielddata.intFieldType == 3) {
        this.UserFielddata.intLangcontrolst = state;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.disabled {
  opacity: 0.5;
  pointer-events: none;
}
form {
  max-width: fit-content;
  height: fit-content;
}
.lookuplist {
  border: solid 1px $black-400;
  .col-6 {
    border: solid 1px $black-400;
  }
}
// .lookup-scrool {
//   max-height: 500px !important;
//   overflow-y: auto !important;
// }
.form-userfield {
  // max-width: 850px;
  width: 850px;
}
.disable-submit {
  pointer-events: none;
}

.btn-link-secondary {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 8px;

  width: 224px;
  height: 24px;
}
</style>
