const apiuserfield = {
  insertFieldList: "/v1/api/AdsUserField/InsertUserfield",
  updateFieldList: "/v1/api/AdsUserField/UpdateUserfield/",
  deleteFieldList: "/v1/api/AdsUserField/DeleteUserfield/",
  getUserFieldList: "/v1/api/AdsUserField/UserFieldList",
  getFieldListByID: "/v1/api/AdsUserField/UserfieldByID/",
  // duplicate: "/v1/api/AdsUserField/FieldListAgency",
  checkdDeleteUserField: "/v1/api/AdsUserField/CheckDeleteuserfield/",
  checkusedstatus: "/v1/api/AdsUserField/CheckUsageStatusUserfield/",
};

export default apiuserfield;
