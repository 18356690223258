<template>
  <div
    v-if="!image"
    :class="
      dropOver == true
        ? 'drop-over'
        : size || msgError != '' || null
        ? 'dropFile-error'
        : 'dropFile'
    "
    class="dropFile d-flex justify-content-center align-items-center p-3"
    :style="style"
  >
    <div
      class="inputFile d-flex justify-content-center flex-column align-items-center"
    >
      <Icon
        icon="akar-icons:image"
        class="text-black-500"
        :class="classIcon"
        :width="widthicon"
        :height="heighticon"
      />
      <p
        class="fnt-body m-0 fnt-light text-black-500"
        :class="
          Labelsize || (subname != '' || subname != null ? 'text-center' : '')
        "
      >
        <!-- {{ ValidNumber(name) ? name : $t(name) }} -->
        <!-- <br>  -->
        <span style="font-size: 10px !important" class="fnt-regular">{{
          $t(subname)
        }}</span>
      </p>
    </div>

    <input
      type="file"
      @change="onFileChange($event)"
      accept="image/jpeg,image/png"
      :class="canClick == true ? 'notclick' : ''"
    />
    {{ image }}
  </div>

  <div
    v-else
    class="d-flex align-items-center justify-content-center p-2 position-relative"
    :class="blnclick == true ? 'imguserfield' : [size, 'dropFile']"
  >
    <img :src="image" alt="" />
    <div
      class="d-flex justify-content-center align-items-center overlay-action"
    >
      <div class="d-flex justify-content-center align-items-center action">
        <Button
          icon="OpenEye"
          class="ms-2"
          classBtnIcon="fill-black-200"
          @click="showImage()"
        />
        <Button
          icon="DeleteFill"
          @click="removeImage()"
          classBtnIcon="fill-black-200"
          class="me-2"
          :class="canClick == true ? 'notclick d-none' : ''"
        />
      </div>
    </div>
  </div>

  <div class="text-black fnt-body mt-2 mx-0 px-0">
    {{ ValidNumber(helper) ? helper : $t(helper) }}
  </div>
  <div class="text-danger fnt-caption mt-2 d-flex align-items-start mb-2 row">
    <span class="col-1 ps-1 pe-0">
      <Icon icon="carbon:warning" class="me-1" v-if="msgError != '' || null" />
    </span>
    <span class="col-11 pe-0">{{ $t(msgError) }}</span>
  </div>

  <div
    class="image-show d-flex align-items-center justify-content-center"
    @click="closeShowimg()"
    v-if="showimage"
  >
    <Button
      icon="CloseOutline"
      classBtnIcon="fill-white"
      widthIcon="40"
      heightIcon="40"
      class="close"
      classBtn="btn"
    />
    <img :src="image" alt="" />
  </div>
</template>

<script>
import { Icon } from "@iconify/vue";
import Button from "@/components/button/button.vue";
// import { images } from "@/components/icon/index";

export default {
  data() {
    return {
      dropOver: false,
      image: "",
      msgError: "",
      showimage: false,
    };
  },
  props: {
    name: { type: String, required: true },
    value: { type: String, required: true },
    alertMsg: { type: String },
    helper: { type: String },
    error: { type: String },
    maxSize: { type: Number },
    size: { type: String },
    widthicon: { type: String, default: "24" },
    heighticon: { type: String, default: "24" },
    subname: { type: String, default: "" },
    canClick: { type: Boolean, default: false },
    style: { type: String },
    index: { type: Number },
    blnclick: { type: Boolean },
  },
  components: {
    Button,
    Icon,
    // images,
  },
  mounted() {
    setTimeout(() => {
      if (this.value != null && this.value != "" && this.value != undefined) {
        this.image = this.value;
        // console.log(this.value);
      }
    }, 1000);
  },
  methods: {
    closeShowimg() {
      if (this.image !== "") {
        this.showimage = false;
      }
    },
    showImage() {
      if (this.image !== "") {
        this.showimage = true;
      } else {
        this.showimage = false;
      }
      // console.log(this.showimage);
    },
    ValidNumber(value) {
      return isNaN(value);
    },
    Valid() {
      return true;
    },
    alertRequest() {
      this.msgError = this.$t(this.alertMsg, {
        fieldname: this.$t(this.name).toLowerCase(),
      });
      this.alertMsgStatus = true;
    },
    onFileChange(e) {
      let file = e.target.files || e.dataTransfer.files;
      if (!file.length) return;

      if (file[0].type != "image/png" || file[0].type != "image/jpeg") {
        this.msgError = "230";
      }
      if (file[0].size > this.maxSize) {
        this.alertMsgStatus = true;
        this.msgError = this.$t("220", { filesize: this.maxSize / 1000000 });
      }
      if (
        file[0].type == "image/png" ||
        (file[0].type == "image/jpeg" && file[0].size < this.maxSize)
      ) {
        if (file[0].size < this.maxSize) {
          this.alertMsgStatus = false;
          this.msgError = "";
          this.createImage(file[0]);
        }
      }
    },
    createImage(file) {
      // var image = new Image();
      var reader = new FileReader();
      var vm = this;
      this.$emit("input", file);
      this.$emit("imgUrl", file);

      reader.onload = (e) => {
        vm.image = e.target.result;
        this.$emit("imgsrc", e);
      };
      reader.readAsDataURL(file);
    },
    removeImage: function () {
      this.image = "";
      this.$emit("remove", this.index);
    },
  },
};
</script>

<style lang="scss">
.image-show {
  position: fixed;
  background: rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100%;
  z-index: 9999;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  img {
    max-width: 80%;
    max-height: 80%;
    border-radius: 4px;
  }
  .close {
    position: absolute;
    top: 50px;
    right: 10%;
  }
}
.overlay-action {
  background: rgba(142, 142, 142, 0.65);
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  transition: 150ms;
}
.imguserfield {
  width: 86px !important;
  height: 86px !important;
  border: dashed 0px $black-500 !important;
  border-radius: 0px !important;
  overflow: hidden;
  background: $white;
  position: relative;
  box-sizing: border-box;

  img {
    width: 100%;
    object-fit: cover;
  }
  .inputFile {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  input {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: transparent;
    z-index: 2;
    opacity: 0;
  }
  .iconUpload {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    svg {
      font-size: 2rem;
    }
    span {
      font-size: 14px;
    }
  }
  .resize {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 3;
    svg {
      font-size: 18px;
      opacity: 0.5;
      &:hover {
        opacity: 1;
      }
    }
  }
}
.dropFile {
  width: 86px !important;
  height: 86px !important;
  border: dashed 1px $black-500 !important;
  border-radius: 4px !important;
  overflow: hidden;
  background: $white;
  position: relative;
  box-sizing: border-box;
  transition: 0.3s;
  &:hover {
    border-color: $primary !important;
    .overlay-action {
      opacity: 1;
      visibility: visible;
    }
  }
  &:active {
    background-color: $black-200;
    border-color: $primary !important;
  }
  img {
    width: 100%;
    object-fit: cover;
  }
  .inputFile {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  input {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: transparent;
    z-index: 2;
    opacity: 0;
  }
  .iconUpload {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    svg {
      font-size: 2rem;
    }
    span {
      font-size: 14px;
    }
  }
  .resize {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 3;
    svg {
      font-size: 18px;
      opacity: 0.5;
      &:hover {
        opacity: 1;
      }
    }
  }
}
.dropFile-error {
  width: 86px !important;
  height: 86px !important;
  border: dashed 1px $danger !important;
  border-radius: 4px !important;
  overflow: hidden;
  background: $white;
  position: relative;
  box-sizing: border-box;
  transition: 0.3s;
  &:hover {
    border-color: $danger !important;
    .overlay-action {
      opacity: 1;
      visibility: visible;
    }
  }
  &:active {
    background-color: $black-200;
    border-color: $danger !important;
  }
  img {
    width: 100%;
    object-fit: cover;
  }
  .inputFile {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  input {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: transparent;
    z-index: 2;
    opacity: 0;
  }
  .iconUpload {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    svg {
      font-size: 2rem;
    }
    span {
      font-size: 14px;
    }
  }
  .resize {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 3;
    svg {
      font-size: 18px;
      opacity: 0.5;
      &:hover {
        opacity: 1;
      }
    }
  }
}
.drop-over {
  background-color: #cacaca !important;
  transition: 0.3s;
}
.drop-leave {
  background-color: #fff;
  transition: 0.3s;
}
.sizeLarge {
  width: 500px !important;
  height: 500px !important;
}
.sizeMedium {
  width: 99px !important;
  height: 114px !important;
}
.sizeSmall {
  max-width: 60px !important;
  max-height: 60px !important;
  border-radius: 2.5px !important;
  border: dashed 1px $black-500 !important;
  svg {
    width: 16px;
    height: 16px;
  }
  button {
    padding: 0 !important;
    svg {
      width: 20px;
      height: 20px;
    }
  }
}
.sizeSmall-edit {
  max-width: 50px !important;
  max-height: 50px !important;
  border-radius: 2.5px !important;
  border: dashed 1px $black-500 !important;
  svg {
    width: 16px;
    height: 16px;
  }
  button {
    padding: 0 !important;
    svg {
      width: 20px;
      height: 20px;
    }
  }
}
.label-vw {
  font-size: 0.9vw !important;
}
.fit-100 {
  width: 100% !important;
  height: 100% !important;
}
.drop-zone > div {
  width: 100%;
}
.notclick {
  pointer-events: none;
}
</style>
