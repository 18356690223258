<template>
  <AltStatus
    :blnalert="blnAltStatus"
    icon="CorrectFill"
    coloricon="fill-success"
    class="success"
    :name="$t(strSMSGsuccess)"
    :class="blnAltStatus ? 'show' : ''"
  />
  <AltStatus
    :blnalert="blnAltStatusunsucess"
    icon="IncorrectFill"
    coloricon="fill-danger"
    class="error"
    :name="$t('174')"
    @mousemove="hover = true"
    @mouseleave="hover = false"
    :class="blnAltStatusunsucess ? 'show' : ''"
  />
  <AltStatus
    :blnalert="blnAltLoadingData"
    icon="IncorrectFill"
    coloricon="fill-danger"
    class="error"
    :name="$t('223')"
    @mousemove="hover = true"
    @mouseleave="hover = false"
    :class="blnAltLoadingData ? 'show' : ''"
  />
  <!-- ข้อมูลนี้เพิ่งถูกลบไป ไม่สามารถลบได้ -->
  <AltStatus
    :blnalert="blnnotdelete"
    icon="IncorrectFill"
    coloricon="fill-danger"
    class="error"
    :name="$t('428')"
    @mousemove="hover = true"
    @mouseleave="hover = false"
    :class="blnnotdelete ? 'show' : ''"
  />
  <!-- ข้อมูลนี้เพิ่งถูกลบไป ไม่สามารถแก้ไขได้ -->
  <AltStatus
    :blnalert="blnnotedit"
    icon="IncorrectFill"
    coloricon="fill-danger"
    class="error"
    :name="$t('222')"
    @mousemove="hover = true"
    @mouseleave="hover = false"
    :class="blnnotedit ? 'show' : ''"
  />
  <div
    class="popup-container align-items-center"
    v-show="blnPopupConfirmDelete == true && intWrongLogin < intPinCount"
  >
    <transition name="zoom">
      <AlertPopup
        v-show="blnPopupConfirmDelete == true && intWrongLogin < intPinCount"
        type="Danger"
        classOkBtn="btn-danger"
        artIcon="deleteFill"
        :altMsgBox="$t('186', { fieldname: $t('4').toLocaleLowerCase() })"
        :Helper="$t('345')"
        @confirm="fetchDeleteUserField($event)"
        :blnLoading="blnloadConfirmDelet"
        ref="AlertPopup"
        :showpass="true"
        :btnpop="true"
        classIcon="fill-danger"
        :errorMsg="$t(errorMsg)"
        :blnError="blnError"
      />
    </transition>
    <transition name="fade">
      <div
        class="overlay"
        v-show="blnPopupConfirmDelete == true && intWrongLogin < intPinCount"
        @click="blnloadConfirmDelet == false ? ConfirmDelete() : ''"
      ></div>
    </transition>
  </div>
  <div
    class="popup-container align-items-center"
    v-show="blnPopupConfirmDelete == true && intWrongLogin >= intPinCount"
  >
    <transition name="zoom">
      <AlertPopup
        v-show="blnPopupConfirmDelete == true && intWrongLogin >= intPinCount"
        type="Warning"
        classOkBtn="btn-secondary"
        artIcon="WarningOutline"
        :altMsgBox="$t('1287', { LONGINFAILNUM: intPinCount, LockContact: strTel })"
        :Helper="$t('345')"
        @confirm="reloadpage($event)"
        ref="AlertPopupp"
        :btnpop="true"
        classIcon="fill-warning"
        :Cancel="false"
      />
    </transition>
    <transition name="fade">
      <div
        class="overlay"
        v-show="blnPopupConfirmDelete == true && intWrongLogin >= intPinCount"
        @click="reloadpage($event)"
      ></div>
    </transition>
  </div>
  <Header
    icon="userfieldOutline"
    :headerName="$t('4')"
    :btnName="$t('124')"
    :breadcrumbname="
      action == 'Insert'
        ? $t('124')
        : '' || action == 'Update'
        ? $t('426', { fieldname: $t('4').toLowerCase() })
        : ''
    "
    class="mb-3"
    @onInput="
      {
        action = 'Insert';
        openform($event);
      }
    "
    :breadcrumb="blnOpenform == true ? '' : 'd-none'"
    :helperHeader="
      blnOpenform == true ? $t('200', { fieldname: $t('4').toLowerCase() }) : ''
    "
    :hintHelperHeader="blnOpenform == true ? '' : 'd-none'"
    :rightHeader="blnOpenform == true ? 'd-none' : ''"
    :underline="blnOpenform == true ? 'openUnderline' : 'closeUnderline'"
    @showFilter="openFilter($event)"
    id="header"
    ListUserFieldClass="d-none"
    :Searchname="$t('125')"
    @onSearch="SearchUserField($event)"
    @onClean="clean()"
    :blnOpenform="blnOpenform"
    :showFilter="blnOpenFilter"
    :filterActive="{
      blnOpenform: blnOpenFilter,
      useFilter:
        activeFiltersSelected.length == 0 || activeFiltersSelected.length == 10
          ? false
          : true,
      disableFilter: false,
    }"
    ref="header"
  />
  <div class="popup-container" v-show="blnOpenFilter == true">
    <transition name="zoom">
      <Filter
        v-show="blnOpenFilter == true"
        @appectFilter="filteredData($event)"
        :listDataFilter="listUserFieldFilter"
        @Cancel="blnOpenFilter = !blnOpenFilter"
        :listActiveFiltersSelected="activeFiltersSelected"
        :blnOpenFilter="blnOpenFilter"
        ref="filter"
        btnFix="btn-group-fix"
        classFilter="filter-fix"
      />
    </transition>
    <transition name="fade">
      <div
        class="overlay"
        v-show="blnOpenFilter == true"
        @click="blnOpenFilter = false"
      ></div>
    </transition>
  </div>
  <Table
    :progressScore="progress"
    :nametable="'4'"
    v-show="ListUserField.dataFields != null"
    v-if="!blnOpenform"
    :ListDataTable="ListUserField"
    :blnHaveDecision="false"
    firstSort="-"
    secondSort="strFieldName"
    @edit="fetchGetUserFieldbyId($event)"
    @delete="fetchDeleteUserField($event)"
    @ConfigDisplay="reloadConfigDisplay()"
    @onSaveConfigDisplay="onSaveConfigDisplay($event)"
    ref="table"
  />
  <div
    class="d-flex mt-sm-4 ms-lg-9 ms-md-0 m-sm-0 justify-content-start align-items-start w-100"
    v-if="blnOpenform == true"
    :class="
      device == 'mobile' || device == 'tablet'
        ? 'flex-wrap move-to-top-level'
        : 'mb-4'
    "
  >
    <UserfieldForm
      ref="userfield"
      @Insert="fetchInsertUserField($event)"
      @Update="fetchUpateUserField($event)"
      @delete="ConfirmDelete()"
      @confirm="
        (e) => {
          confirmlabel = e;
        }
      "
      :formdata="formdata"
      :action="action"
      v-if="blnOpenform"
      @onInput="openform($event)"
      :inactive="formdata.intFieldType == 1 ? true : forminactive"
      :msgalertDataDuplicate="msgalertDataDuplicate"
      :iscandelete="listRelate.used"
      :ScrollRepeat="strScrollRepeat"
    />
    <!-- {{Relateinactive}} -->
    <!-- </div> -->
    <Relate
      :class="device == 'mobile' || device == 'tablet' ? 'mt-3 mb-3 w-100' : ''"
      v-if="blnOpenform == true && listRelate.used == true"
      :listRelate="listRelate"
      :label="$t('418', { fieldname: $t('4').toLowerCase() })"
      style="width: 350px"
      class="layout"
    />
  </div>
</template>

<script>
import AlertPopup from "@/components/alert/alertPopup.vue";
import Relate from "@/components/cards/relate.vue";
import UserfieldForm from "@/components/form/UserField.vue";
import Filter from "@/components/filter/filter.vue";
import Header from "@/components/headerMenu/haeder.vue";
import Table from "@/components/table/table.vue";
import AltStatus from "@/components/alert/alertStatus.vue";
//import Form from "../components/form/form.vue";
import APIUserField from "@/typescript/urlapi/adminsystem/APIUserField";
import AdsInventories from "@/typescript/inventory";

import axios from "axios";
import urlapi from "@/typescript/urlapi/url";
import Enumerable from "linq";
import apiuserfield from "@/typescript/urlapi/adminsystem/APIUserField";
import Login from "@/typescript/login";
import VueCookies from "vue-cookies";
import cookiesPath from "@/typescript/urlapi/cookiesPath";

export default {
  components: {
    Header,
    Table,
    UserfieldForm,
    Filter,
    AltStatus,
    Relate,
    AlertPopup,
  },

  data() {
    return {
      blnloadConfirmDelet: false,
      blnloadError: false,
      strSearch: "",
      confirmlabel: false,
      intWrongLogin: 0,
      intPinCount: 1,
      blnPopupConfirmDelete: false,
      strSearchField: ["strFieldName", "strLabelEN", "strLabelTH"],
      listRelate: [],
      timerCountHover: 0,
      progress: 0,
      strSMSGsuccess: "",
      blnAltStatus: false, //Alt Status Success
      blnAltStatusunsucess: false, //Alt Status unsuccess
      blnAltLoadingData: false, //Alt Status logint
      hover: false, //hover alt
      formdata: {},
      blnOpenform: false,
      blnnotedit: false,
      blnnotdelete: false,
      ListUserField: {},
      ListDummy: {},
      listSearch: {},
      action: "Insert",
      blnOpenFilter: false,
      blnopenConfigDisplay: false,
      updateAdsInventories: null,
      listUserFieldFilter: [
        {
          list: [
            { name: "127" },
            {
              data: [
                { id: null, lang: "79" }, //all
                { id: 3, lang: "135" }, //Variable
                { id: 1, lang: "136" }, //control
                { id: 4, lang: "137" }, //Lookup
                { id: 2, lang: "138" }, //Fixed
              ],
            },
            { state: "intFieldType" },
          ],
        },
        {
          list: [
            { name: "129" },
            {
              data: [
                { id: null, lang: "79" }, //all
                { id: 1, lang: "120" }, //Text
                { id: 2, lang: "121" }, //Number
                { id: 3, lang: "123" }, //Currency
                { id: 4, lang: "139" }, //Date
                { id: 5, lang: "140" }, //Time
                { id: 6, lang: "146" }, //Date/Time
              ],
            },
            { state: "strDatatype" },
          ],
        },
        {
          list: [
            { name: "131" },
            {
              data: [
                { id: null, lang: "79" }, //all
                { id: 0, lang: "141" }, //Not require
                { id: 1, lang: "131" }, //Require
              ],
            },
            { state: "intRequire" },
          ],
        },
        {
          list: [
            { name: "132" },
            {
              data: [
                { id: null, lang: "79" }, //all
                { id: 0, lang: "142" }, //Not idenifier
                { id: 1, lang: "132" }, //Idenifier
              ],
            },
            { state: "intIdentifier" },
          ],
        },
        {
          list: [
            { name: "133" },
            {
              data: [
                { id: null, lang: "79" }, //all
                { id: 0, lang: "143" }, //Uncheck
                { id: 1, lang: "133" }, //Check
              ],
            },
            { state: "intRepeatable" },
          ],
        },
        {
          list: [
            { name: "134" },
            {
              data: [
                { id: null, lang: "79" }, //all
                { id: 1, lang: "134" }, //Uncheck
                { id: 0, lang: "144" }, //Check
              ],
            },
            { state: "intNotnull" },
          ],
        },
        {
          list: [
            { name: "191" },
            {
              data: [
                { id: null, lang: "79" }, //all
                { id: 0, lang: "236" }, //Uncheck
                { id: 1, lang: "191" }, //Check
              ],
            },
            { state: "intSearchflag" },
          ],
        },
      ],
      activeFiltersSelected: [],
      forminactive: false,
      msgalertDataDuplicate: [], //msg
      errorMsg: "",
      strScrollRepeat: "",
    };
  },
  unmounted() {
    clearInterval(this.updateAdsInventories);
  },
  mounted() {
    this.updateAdsInventories = setInterval(() => {
      // console.log(AdsInventories.blnCloseform);
      if (AdsInventories.blnCloseform == false) {
        this.openform(false);
        AdsInventories.blnCloseform = true;
      }
    }, 300);
  },
  watch: {
    timerEnabled(value) {
      if (value) {
        setTimeout(() => {
          this.timerCount++;
        }, 1000);
      }
    },
    hover(value) {
      if (value == false) {
        setTimeout(() => {
          this.timerCount++;
        }, 1000);
      }
    },
    timerCountHover: {
      handler(value) {
        if (value < 2 && this.timerEnabled && this.hover == false) {
          setTimeout(() => {
            this.timerCount++;
          }, 1000);
        }
        if (value >= 2) {
          this.blnAltStatusunsucess = false;
          this.blnAltLoadingData = false;
        }
      },
      immediate: true, // This ensures the watcher is triggered upon creation
    },
    // timerCount: {
    //   handler(value) {
    //     if (value < 10 && this.timerEnabled && this.progress < 75) {
    //       setTimeout(() => {
    //         this.timerCount++;

    //         this.progress += 5 * this.timerCount;
    //       }, 200);
    //     }
    //   },

    //   immediate: true, // This ensures the watcher is triggered upon creation
    // },
  },
  created() {
    this.processLoadingData();
  },
  methods: {
    async processLoadingData() {
      this.progress = 0;
      this.ListUserField = [];
      this.ListDummy = [];
      this.listRelate = [];
      await this.fetchListUserField().then(() => {
        this.processData().then(() => {
          setTimeout(() => {
            // this.progress += 100;
            if (this.blnloadError == false) {
              this.progress = 100;
            }
          }, 1000);
        });
      });
    },
    async processData() {
      try {
        setTimeout(() => {
          var reversed = Enumerable.from(this.ListUserField.data)
            .orderByDescending((r) => r.strFieldName.toLowerCase())

            .toArray();

          this.ListUserField.data = reversed.reverse();
        }, 200);

        if (
          localStorage.getItem("SelectedListUserField") == null ||
          localStorage.getItem("SelectedListUserField") == "undefined"
        ) {
          localStorage.setItem(
            "SelectedListUserField",
            JSON.stringify(this.ListUserField.defaultField)
          );
        }

        //delay 1 sec
        setTimeout(() => {
          if (this.$refs.table != null) {
            this.$refs.table.onClean();
            this.$refs.table.calculatePage(
              this.ListUserField.total,
              this.ListUserField.data
            );
            // ค่า config ไม่เป็น undefined
            if (localStorage.getItem("SelectedListUserField") != "undefined") {
              this.$refs.table.setSelectedList(
                JSON.parse(localStorage.getItem("SelectedListUserField"))
              );
            } else {
              // ค่า config เป็น undefined ให้ใช้ config จาก defaultfield
              // และ set ค่า config นั้นไว้
              this.$refs.table.setSelectedList(this.ListUserField.defaultField);
              localStorage.setItem(
                "SelectedListUserField",
                JSON.stringify(this.ListUserField.defaultField)
              );
            }
          }
          if (this.$refs.filter != null) {
            this.$refs.filter.Clear();
          }
        }, 1000);
      } catch (error) {
        this.blnAltLoadingData = true;
        this.timerCountHover = 0;
        this.timerEnabled = true;
        this.hover = false;
        setTimeout(() => {
          this.blnAltLoadingData = false;
        }, 2000);
        throw new Error(error);
      }
    },

    createBase64Image(fileObject) {
      const reader = new FileReader();
      return reader.readAsBinaryString(fileObject);
    },

    async fetchUpateUserField(data) {
      // console.log(data);
      try {
        this.msgalertDataDuplicate = [];
        if (this.$refs.header != null) {
          this.$refs.header.onClear();
        }

        let isEqual =
          JSON.stringify(this.formdataDummy) === JSON.stringify(data);
        // console.log(isEqual);
        if (isEqual == true) {
          this.openform(false);
        } else {
          // console.log(data.formdata);

          // clear data
          data.formdata = await this.clearDataForUpdate(data.formdata);
          // console.log(data.formdata);
          if (data.formdata.strFileID == undefined) {
            data.formdata.strFileID = "";
          }

          const formData = new FormData();

          await Object.keys(data.formdata).forEach((key) => {
            // console.log(key);
            if (
              data.formdata[key] !== null &&
              data.formdata[key] !== undefined &&
              data.formdata[key] !== "" &&
              data.formdata[key].length !== 0
            ) {
              if (key !== "strImageLookupValue") {
                if (key == "strDatatype") {
                  formData.append(key, data.formdata[key].toString());
                } else if (key == "strIcon") {
                  // console.log(typeof data.formdata["strIcon"]);
                  if (typeof data.formdata["strIcon"] !== "string") {
                    formData.append(key, data.formdata[key]);
                  }
                } else if (key == "strLookupValue") {
                  // console.log(data.formdata[key]);
                  // console.log(JSON.stringify(data.formdata[key]));
                  formData.append(key, JSON.stringify(data.formdata[key]));
                } else if (key == "strFileID") {
                  data.formdata[key].forEach((ele) => {
                    formData.append(key, ele);
                  });
                } else if (key == "listImageLookup") {
                  // UpdateImage
                  formData.append(
                    "strListImageLookup",
                    JSON.stringify(data.formdata[key])
                  );
                  formData.append(key, data.formdata[key]);
                } else {
                  // console.log(key + " : " + data.formdata[key]);
                  formData.append(key, data.formdata[key]);
                }
              }
            }
          });
          // for (let [key, value] of formData.entries()) {
          //   console.log(`${key}: ${value}`);
          // }

          var res = await axios.put(
            urlapi +
              APIUserField.updateFieldList +
              this.UserFieldId +
              "?MemberID=" +
              AdsInventories.userinfo.intMemberID +
              "&blnConfirm=" +
              this.confirmlabel,
            formData,
            {
              headers: { "Content-Type": "multipart/form-data" },
            }
          );
          // console.log(res);
          // console.log(this.confirmlabel);

          if (res.data.edited == true) {
            this.blnOpenform = false;
            this.confirmlabel = false;
            this.blnAltStatus = true;
            this.strSMSGsuccess = "27";
            setTimeout(() => {
              this.blnAltStatus = false;
            }, 2000);
            this.progress = 0;
            //delay 1 sec
            setTimeout(() => {
              //ถ้าบันทึกสำเร็จ ทำการเรียกข้อมูลใหม่อีกครั้ง
              this.processLoadingData();
            }, 1000);
          } else if (res.data.edited == false) {
            // console.log(res.data.edited);
            this.msgalertDataDuplicate = [];
            this.strScrollRepeat = "";
            if (res.data.fieldnameEN == true) {
              // console.log("Dup");
              let Message = { name: "strFieldName", msg: "221" };
              this.msgalertDataDuplicate.push(Message);
              this.strScrollRepeat = "FieldName";
            }
            if (res.data.blnTextDisplayEN == true) {
              let Message = { name: "strLabelEN", msg: "221" };
              this.msgalertDataDuplicate.push(Message);
              this.strScrollRepeat = "TextDisplatEN";
            }
            if (res.data.blnTextDisplayTH == true) {
              let Message = { name: "strLabelTH", msg: "221" };
              this.msgalertDataDuplicate.push(Message);
              this.strScrollRepeat = "TextDisplatTH";
            }
            // console.log(this.msgalertDataDuplicate);
            this.$refs.userfield.detectalertDataDuplicate(
              this.msgalertDataDuplicate
            );

            if (
              res.data.message ==
              "Cannot edit ,Please check mapping Id in interface"
            ) {
              this.blnOpenform = false;
              this.blnnotedit = true;
              setTimeout(() => {
                this.blnnotedit = false;
                this.processLoadingData();
              }, 5000);
            }
          } else if (res.data.code === 500) {
            this.blnAltStatusunsucess = true;
            this.timerCountHover = 0;
            this.timerEnabled = true;
            setTimeout(() => {
              this.blnAltStatusunsucess = false;
            }, 2000);
          } else {
            this.blnAltStatusunsucess = true;
            this.timerCountHover = 0;
            this.timerEnabled = true;
            setTimeout(() => {
              this.blnAltStatusunsucess = false;
            }, 2000);
          }
        }
      } catch (error) {
        console.log(error);
        this.blnAltStatusunsucess = true;
        this.timerCountHover = 0;
        this.timerEnabled = true;
        setTimeout(() => {
          this.blnAltStatusunsucess = false;
        }, 2000);
        throw new Error(error);
      }
    },

    clearDataForUpdate(UserFielddata) {
      // console.log(UserFielddata);
      if (UserFielddata.intFieldType == 2) {
        if (UserFielddata.strDatatype != 8) {
          UserFielddata.strImageValue = "";
          UserFielddata.strOldImageValue = "";
          UserFielddata.strLookupValue = [];
          UserFielddata.strImageLookupValue = [];
          UserFielddata.listImageLookup = [];
        } else if (UserFielddata.strDatatype == 8) {
          // UserFielddata.strValue = " ";
          UserFielddata.strOldImageValue = " ";
          // UserFielddata.strImageValue = "";
          UserFielddata.strLookupValue = [];
          if (this.action == "Insert") {
            UserFielddata.listImageLookup = [];
          } else if (this.action == "Update") {
            UserFielddata.strImageLookupValue = [];
          }
          // console.log(UserFielddata);
        }
      } else if (UserFielddata.intFieldType == 3) {
        UserFielddata.strValue = " ";
        UserFielddata.strImageValue = "";
        UserFielddata.strOldImageValue = "";
        UserFielddata.strLookupValue = [];
        UserFielddata.strImageLookupValue = [];
        UserFielddata.listImageLookup = [];
      } else if (
        UserFielddata.intFieldType == 4 &&
        UserFielddata.strDatatype != 8
      ) {
        UserFielddata.strValue = " ";
        UserFielddata.strImageValue = "";
        UserFielddata.strOldImageValue = "";
        UserFielddata.strImageLookupValue = [];
        UserFielddata.listImageLookup = [];
      } else if (
        UserFielddata.intFieldType == 4 &&
        UserFielddata.strDatatype == 8
      ) {
        UserFielddata.strValue = " ";
        UserFielddata.strImageValue = "";
        UserFielddata.strOldImageValue = "";
        UserFielddata.strLookupValue = "";
        if (this.action == "Insert") {
          UserFielddata.listImageLookup = [];
        } else if (this.action == "Update") {
          UserFielddata.strImageLookupValue = [];
        }
      }
      return UserFielddata;
    },

    async fetchGetUserFieldbyId(UserFieldId) {
      // check Relate
      try {
        var resRelate = await axios.get(
          urlapi + APIUserField.checkusedstatus + UserFieldId
        );
        this.listRelate = { ...resRelate.data };
        this.forminactive = this.listRelate.used;
        // console.log(this.listRelate);
      } catch (error) {
        console.log(error);
      }

      this.UserFieldId = UserFieldId;
      await axios
        .get(urlapi + APIUserField.getFieldListByID + UserFieldId)
        .then((res) => {
          // console.log(res.data);
          this.action = "Update";

          this.formdata = res.data.data;
          this.formdataDummy = { ...res.data.data };
          this.blnOpenform = true;
          if (
            res.data.message ==
            "Not found ,Please check mapping Id in interface"
          ) {
            this.blnOpenform = false;
            this.blnnotedit = true;
            setTimeout(() => {
              this.blnnotedit = false;
              this.processLoadingData();
            }, 5000);
          }
        });
    },
    //ลบข้อมูลด้วย ID
    async fetchDeleteUserField(state) {
      try {
        // console.log(state);
        this.blnloadConfirmDelet = true;
        if (state === false) {
          this.blnPopupConfirmDelete = !this.blnPopupConfirmDelete;
          this.blnloadConfirmDelet = false;
        } else {
          if (
            this.$refs.AlertPopup.password != null &&
            this.$refs.AlertPopup.password != ""
          ) {
            var strUsername = AdsInventories.userinfo.strUsername.split("@");
            var reslogin = await Login.login(
              strUsername[0],
              state,
              axios,
              urlapi
            );

            var login = {
              username: strUsername[0],
              password: state,
            };

            // console.log(reslogin);
            if (reslogin.logined == true) {
              this.intPinCount = reslogin.pincode;
              this.strTel = reslogin.tel;
              var res = await axios.delete(
                urlapi + apiuserfield.deleteFieldList + this.UserFieldId,
                {
                  data: login,
                },

                { headers: { "Content-Type": "application/json" } }
              );
              // console.log(res);
              if (res.data.candelete == true) {
                this.blnError = false;
                this.blnPopupConfirmDelete = false;
                this.strSMSGsuccess = "28";
                this.blnOpenform = false;
                this.blnAltStatus = true;
                if (this.$refs.header !== undefined) {
                  this.$refs.header.onClear();
                }
                this.progress = 0;
                //delay 1 sec
                setTimeout(() => {
                  this.processLoadingData();
                  this.blnAltStatus = false;
                }, 1000);
              } else {
                this.blnOpenform = false;
                // this.fetchGetCampusbyId(this.campusId);

                this.timerCountHover = 0;
                this.timerEnabled = true;
                this.hover = false;
                this.blnPopupConfirmDelete = false;
                // reset PIN หลังจากDelete
                this.$refs.AlertPopup.password = null;

                if (
                  res.data.message ==
                  "Cannot delete ,Please Check mapping Id in interface"
                ) {
                  //ข้อมูลนี้เพิ่งถูกลบไป ไม่สามารถลบได้
                  this.blnnotdelete = true;
                  setTimeout(() => {
                    this.blnnotdelete = false;
                    this.processLoadingData();
                  }, 5000);
                } else {
                  //ข้อมูลนี้เพิ่งถูกใช้งาน ไม่สามารถลบได้
                  this.blnAltLoadingData = true;
                  setTimeout(() => {
                    this.blnAltLoadingData = false;
                    this.processLoadingData();
                  }, 5000);
                }
              }
              this.blnPopupConfirmDelete = false;
            } else {
              // console.log(reslogin);
              this.blnloadConfirmDelet = false;
              this.intPinCount = reslogin.pincode;
              this.strTel = reslogin.tel;
              this.intWrongLogin = reslogin.WrongLogin;
              this.errorMsg = this.$t("357", {
                number: this.intPinCount - this.intWrongLogin,
              });
              this.blnError = true;
              this.$refs.AlertPopup.blnShowMSG = true;
              if (this.intWrongLogin >= this.intPinCount) {
                VueCookies.remove("UserInfo", "/", cookiesPath);
              }
            }
            this.blnloadConfirmDelet = false;
          } else {
            this.errorMsg = this.$t("202", { fieldname: "(PIN)" });
            this.blnError = true;
            this.$refs.AlertPopup.blnShowMSG = true;
            this.blnloadConfirmDelet = false;
          }
        }
      } catch (error) {
        this.$refs.AlertPopup.blnShowMSG = true;
        // this.blnAltLoadingData = true;
        // this.timerCountHover = 0;
        // this.timerEnabled = true;
        // this.hover = false;
        console.log(error);
      }
    },
    async fetchListUserField() {
      try {
        this.blnloadError = true;
        var res = await axios.get(urlapi + APIUserField.getUserFieldList);

        this.ListUserField = res.data;
        // console.log(this.ListUserField);
        this.ListDummy = { ...this.ListUserField };
        //await this.fetchCheckDeleteUserField();
        this.blnloadError = false;
      } catch (e) {
        // this.blnAltLoadingData = true;
        this.blnAltStatusunsucess = true;
        this.timerCountHover = 0;
        this.timerEnabled = true;
        this.hover = false;
        setTimeout(() => {
          this.blnAltStatusunsucess = false;
          // this.blnAltLoadingData = false;
        }, 2000);
        console.log(e);
      }
    },
    ConfirmDelete() {
      // console.log("click button delete");
      if (this.$refs.AlertPopup != null) {
        this.$refs.AlertPopup.password = null;
        this.$refs.AlertPopup.blnShowMSG = false;
      }
      this.blnError = false;
      this.blnPopupConfirmDelete = !this.blnPopupConfirmDelete;
    },
    reloadpage() {
      Login.logout();
      //ไปยังหน้าแรก
      // setTimeout(() => {
      //   this.$router.push("/login");
      //   this.$router.go();
      // }, 100);
    },

    reloadConfigDisplay() {
      if (
        this.$refs.table != null &&
        localStorage.getItem("SelectedListUserField") != "undefined"
      ) {
        this.$refs.table.setSelectedList(
          JSON.parse(localStorage.getItem("SelectedListUserField"))
        );
      }
    },

    findByTemplate(activeFilters, array) {
      return array.filter((item) => {
        return item[activeFilters.state] == activeFilters.id;
      });
    },
    //ตรวจสอบว่าสามารถลบได้หรือไหม
    fetchCheckDeleteUserField() {
      if (Array.isArray(this.ListUserField.data)) {
        this.ListUserField.data.forEach((element, index) => {
          if (typeof element !== "undefined") {
            axios
              .get(urlapi + APIUserField.checkdDeleteUserField + element.strID)
              .then((res) => {
                // ถ้าลบได้ เก็บค่าตัวแปร candelete เป็น true
                if (res.data.candelete == true) {
                  this.ListUserField.data[index]["candelete"] = true;
                }
                //ถ้าลบไม่ได้ เก็บค่าตัวแปร candelete เป็น false
                else {
                  this.ListUserField.data[index]["candelete"] = false;
                }
              })
              .catch((e) => {
                this.blnAltLoadingData = true;
                this.timerCountHover = 0;
                this.timerEnabled = true;
                this.hover = false;
                setTimeout(() => {
                  this.blnAltLoadingData = false;
                }, 2000);
                console.log(e);
              });
          }
        });
      }

      this.progress += 10;
    },
    //Filter รับค่า จากตัวกรองกับข้อมูลชุดเดิม
    filter(activeFilters, ListDummy) {
      // console.log(activeFilters);

      this.ListUserField.data = { ...ListDummy };
      let filterArrayintFieldType = [];
      for (let field in this.ListUserField.data) {
        for (let active in activeFilters) {
          if (activeFilters[active]["state"] == "intFieldType") {
            if (
              this.ListUserField.data[field]["intFieldType"] ==
              activeFilters[active]["id"]
            ) {
              filterArrayintFieldType.push(this.ListUserField.data[field]);
            }
          }
        }
      }
      // console.log(filterArrayintFieldType);

      var filterArraystrDatatype = [];
      for (let field in filterArrayintFieldType) {
        for (let active in activeFilters) {
          if (activeFilters[active]["state"] == "strDatatype") {
            if (
              filterArrayintFieldType[field]["strDatatype"] ==
              activeFilters[active]["id"]
            ) {
              filterArraystrDatatype.push(filterArrayintFieldType[field]);
            }
          }
        }
      }

      // console.log(filterArraystrDatatype);
      // if (filterArray.length == 0) {
      //   filterArray = ListDummy.slice();
      // }
      let filterArray = filterArraystrDatatype.slice();
      for (var i in activeFilters) {
        if (
          activeFilters[i].state !== "intFieldType" &&
          activeFilters[i].state !== "strDatatype"
        ) {
          filterArray = this.findByTemplate(activeFilters[i], filterArray);
        }
      }

      this.ListUserField.data = filterArray;

      // console.log(filterArray);
      if (this.$refs.table != null) {
        this.$refs.table.onClean();
        this.$refs.table.calculatePage(filterArray.length, filterArray);
      }
      this.$refs.table.SearchListDataTable(this.strSearch, this.strSearchField);

      this.blnOpenFilter = false;

      this.ListDummy.data = { ...ListDummy };
    },
    filteredData(activeFilters) {
      this.activeFiltersSelected = activeFilters;
      if (activeFilters.length != 0) {
        this.filter(activeFilters, this.ListDummy.data);
      } else {
        this.ListUserField.data = this.ListDummy.data;
        if (this.$refs.table != null) {
          this.$refs.table.onClean();
        }
      }
    },

    onSaveConfigDisplay(SelectedList) {
      localStorage.setItem(
        "SelectedListUserField",
        JSON.stringify(SelectedList)
      );
    },
    //เปิดฟอร์ม
    async openform(state) {
      try {
        this.activeFiltersSelected = [];
        console.log(state);
        //ปิดฟอร์ม
        if (state == false) {
          if (this.$refs.header !== undefined) {
            this.$refs.header.onClear();
          }
          await this.processLoadingData();
          //เคลียข้อมูลในฟอร์ม
          this.formdata = {};
        }
        //เปิดฟอร์ม
        else if (state == true) {
          //กำหนด action insert

          //เคลียข้อมูลในฟอร์ม
          this.formdata = {};
          if (this.action == "Insert") {
            this.forminactive = true; //inactive delete
          }
        }
        this.blnOpenform = state;
      } catch (error) {
        console.log(error);
      }
    },

    // //บันทึกข้อมูลของ UserField ไปยัง Database
    async fetchInsertUserField(data) {
      // console.log(data);
      // console.log(typeof data);
      if (typeof data !== "undefined") {
        // console.log(JSON.stringify(data));

        // data.strLookupValue =
        //   data.strLookupValue.length == 0
        //     ? null
        //     : JSON.stringify({ ...data.strLookupValue });
        //var stringToJsonObject = JSON.stringify(data);

        // clear data
        data = await this.clearDataForUpdate(data);

        let formData = new FormData();
        Object.keys(data).forEach((key) => {
          // console.log(key);
          if (
            data[key] !== null &&
            data[key] !== undefined &&
            data[key] !== "" &&
            data[key].length !== 0
          ) {
            if (key == "strDatatype") {
              formData.append(key, data[key].toString());
            } else if (key == "strLookupValue") {
              // console.log(data[key]);
              // console.log(JSON.stringify(data[key]));
              formData.append(key, JSON.stringify(data[key]));
            } else if (key == "strIcon") {
              // console.log(typeof data["strIcon"]);
              if (typeof data["strIcon"] !== "string") {
                formData.append(key, data[key]);
              }
            } else if (key == "strImageLookupValue") {
              // console.log(data[key][0]);
              data[key].forEach((ele) => {
                formData.append(key, ele);
              });
            } else {
              // console.log(key + " : " + data[key]);
              formData.append(key, data[key]);
            }
          }
        });
        // console.log(formData);
        try {
          var res = await axios
            .post(
              urlapi +
                APIUserField.insertFieldList +
                "?MemberID=" +
                AdsInventories.userinfo.intMemberID +
                "&blnConfirm=" +
                this.confirmlabel,
              formData,
              {
                headers: { "Content-Type": "multipart/form-data" },
              }
            )
            .catch((err) => {
              console.log(err);
            });
          //+ "/" + this.confirmlabel
          //this.formdata = res.data;
          //this.blnOpenform = true;
          // await console.log(res);
          if (res.data.inserted == true) {
            this.blnOpenform = false;
            this.confirmlabel = false;
            this.blnAltStatus = true;
            this.strSMSGsuccess = "26";

            await setTimeout(() => {
              this.blnAltStatus = false;
            }, 2000);
            this.progress = 0;
            //delay 1 sec
            await setTimeout(() => {
              //ถ้าบันทึกสำเร็จ ทำการเรียกข้อมูลใหม่อีกครั้ง
              this.processLoadingData();
            }, 1000);
          }
          if (res.data.inserted == false) {
            this.msgalertDataDuplicate = [];
            this.strScrollRepeat = "";
            if (res.data.fieldnameEN == true) {
              // console.log("Dup");
              let Message = { name: "strFieldName", msg: "221" };
              this.msgalertDataDuplicate.push(Message);
              this.strScrollRepeat = "FieldName";
            }
            if (res.data.blnTextDisplayEN == true) {
              let Message = { name: "strLabelEN", msg: "221" };
              this.msgalertDataDuplicate.push(Message);
              this.strScrollRepeat = "TextDisplatEN";
            }
            if (res.data.blnTextDisplayTH == true) {
              let Message = { name: "strLabelTH", msg: "221" };
              this.msgalertDataDuplicate.push(Message);
              this.strScrollRepeat = "TextDisplatTH";
            }
            if (this.$refs.userfield != null) {
              this.$refs.userfield.detectalertDataDuplicate(
                this.msgalertDataDuplicate
              );
            }
          }
          if (res.data.code === 500 || res.data.insert == false) {
            this.blnAltStatusunsucess = true;
            setTimeout(() => {
              this.blnAltStatusunsucess = false;
            }, 5000);
            this.timerCountHover = 0;
            this.timerEnabled = true;
          }
        } catch (error) {
          console.log(error);
          this.blnAltStatusunsucess = true;
          setTimeout(() => {
            this.blnAltStatusunsucess = false;
          }, 5000);
          this.timerCountHover = 0;
          this.timerEnabled = true;
          this.hover = false;
          throw new Error(error);
        }
        return true;
      }
    },

    openFilter(showFilter) {
      this.blnOpenFilter = showFilter;
    },
    // blnopenConfigDisplay(showDisplay) {
    //   this.blnopenConfigDisplay = showDisplay;

    // },
    SearchUserField(event) {
      this.strSearch = event;
      // console.log(this.strSearch)
      if (this.$refs.table != null) {
        this.$refs.table.SearchListDataTable(event, this.strSearchField);
      }
    },

    clean() {
      this.strSearch = "";
      this.SearchUserField("");
      this.filteredData(this.activeFiltersSelected);
    },
  },
};
</script>

<style lang="scss">
.move-to-top-level .layout:nth-child(odd) {
  order: 2;
  margin-bottom: 16px !important;
}
</style>
